import { Component as ReactComponent, type ErrorInfo, type PropsWithChildren } from 'react';
import { Page } from './layout';

type ErrorBoundaryProps = PropsWithChildren<{}>;

interface ErrorBoundaryState {
    hasError: boolean;
    errorMessage: string;
}

export class ErrorBoundary extends ReactComponent<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, errorMessage: '' };
    }

    static getDerivedStateFromError(error: any): Partial<ErrorBoundaryState> {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, errorMessage: error.message };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        console.log('Unhandled error happened', error, 'ErrorInfo:', errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Page>
                    <Page.Title>Something went wrong.</Page.Title>

                    <Page.Content fullWidth>{this.state.errorMessage}</Page.Content>
                </Page>
            );
        }

        return this.props.children;
    }
}
