import { TextField } from '@cimpress/react-components';
import { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { useSearchParams } from 'react-router-dom';
import { Control, Page } from '../Components';
import { CardBuilder, CustomSelector, PreviewRow, PreviewRows, PreviewSection } from '../Components/common';
import { getAdjustForSubstrateUrl } from '../api';
import { MINIMUM_CONTRAST_RATIO_PARAM, SOURCE_URL_PARAM, TARGET_SUBSTRACT_COLOR_PARAM } from '../constants';
import { isUrl, getUrl, getNumberParam } from '../tools';
import { useSampleDocuments } from '../Components/common/useSampleDocuments';

export const AdjustForSubstratePage = () => {
    const predefinedDocuments = useSampleDocuments('adjustForSubstrate_sources_documents.json');

    const [searchParams, setSearchParams] = useSearchParams();
    const sourceUrlParam = searchParams.get(SOURCE_URL_PARAM);
    const targetSubstrateColorParam = searchParams.get(TARGET_SUBSTRACT_COLOR_PARAM);
    const minimumContrastRatioParam = searchParams.get(MINIMUM_CONTRAST_RATIO_PARAM);

    const [targetSubstrateColor, setTargetSubstrateColor] = useState(targetSubstrateColorParam ?? '#FFFFFF');
    const [minimumContrastRatio, setMinimumContrastRatio] = useState(getNumberParam(minimumContrastRatioParam, 3));
    const [document, setDocument] = useState(sourceUrlParam);

    const [sourceRow, setSourceRow] = useState(PreviewRow());
    const [resultRow, setResultRow] = useState(PreviewRow());

    useEffect(() => {
        const searchParamsObj: Record<string, string> = {};

        if (targetSubstrateColor) {
            searchParamsObj[TARGET_SUBSTRACT_COLOR_PARAM] = targetSubstrateColor;
        }

        if (document) {
            searchParamsObj[SOURCE_URL_PARAM] = document;
        }

        if (minimumContrastRatio) {
            searchParamsObj[MINIMUM_CONTRAST_RATIO_PARAM] = `${minimumContrastRatio}`;
        }

        setSearchParams(searchParamsObj, { replace: true });
    }, [targetSubstrateColor, document, minimumContrastRatio, setSearchParams]);

    useEffect(() => {
        if (!document) {
            setSourceRow(PreviewRow());
            return;
        }

        const sourceCard = CardBuilder.ofType('documentUrl')
            .withTitle('Source with BG #FFFFFF')
            .withData({ documentUrl: document })
            .build();

        setSourceRow(PreviewRow(sourceCard));
    }, [document, setSourceRow]);

    useEffect(() => {
        if (!document || !targetSubstrateColor) {
            setResultRow(PreviewRow());
            return;
        }

        const existingResultCard = CardBuilder.ofType('documentUrl')
            .withTitle(`Source with BG ${targetSubstrateColor}`)
            .withData({ documentUrl: document, backgroundColor: targetSubstrateColor.substring(1) })
            .build();

        const updatedResultCard = CardBuilder.ofType('documentUrl')
            .withTitle(`AGS Result with BG ${targetSubstrateColor}`)
            .withData({
                documentUrl: getAdjustForSubstrateUrl(document, targetSubstrateColor, minimumContrastRatio || 3) ?? '',
                backgroundColor: targetSubstrateColor.substring(1),
            })
            .build();

        setResultRow(PreviewRow(existingResultCard, updatedResultCard));
    }, [document, targetSubstrateColor, minimumContrastRatio, setResultRow]);

    const setDocumentUrl = (docUrlOrToken: string) => {
        setDocument(getUrl(docUrlOrToken));
    };

    return (
        <Page>
            <Page.Title>Adapt to Substrate Color</Page.Title>

            <Page.Controls>
                <Control>
                    <Control.Title>Source Document or Template</Control.Title>
                    <CustomSelector
                        selectorLabel="Document (or template)"
                        textfieldLabel="Document URL, Template URL or Template Token"
                        data={predefinedDocuments}
                        value={document ?? ''}
                        setValue={setDocumentUrl}
                        validator={isUrl}
                    />
                </Control>

                <Control>
                    <Control.Title>Target Substrate Color</Control.Title>
                    <SketchPicker
                        width="65%"
                        color={targetSubstrateColor}
                        onChangeComplete={(e) => setTargetSubstrateColor(e.hex)}
                    />
                </Control>

                <Control>
                    <Control.Title>Minimum Contrast Ratio</Control.Title>
                    <TextField
                        label="Minimum contrast ratio"
                        onChange={(e) => setMinimumContrastRatio(+e.target.value)}
                        type="number"
                        value={minimumContrastRatio}
                        helpText="Value should be in the range of 3 and 4.5 for good results."
                    />
                </Control>
            </Page.Controls>

            <Page.Content>
                <PreviewSection
                    introduction={
                        'This is a page to test the adjust for substrate endpoint. Fill in the source document and target to see the result.'
                    }
                    showIntro={!document}
                    rows={PreviewRows(sourceRow, resultRow)}
                />
            </Page.Content>
        </Page>
    );
};
