import { useState } from 'react';
import { useUpdateEffect } from '../../../tools';
import { Control } from '../../layout';
import { ColorInput } from './ColorInput';
import { isValidColorState } from './isValidColorState';
import type { ColorPalette } from './types';

export interface ColorPaletteEditorProps {
    initialValue?: ColorPalette;
    onChange: (value?: ColorPalette) => void;
}

export interface ColorState {
    value: string;
    isValid: boolean;
}

function getColorState(color = ''): ColorState {
    return {
        value: color,
        isValid: isValidColorState(color),
    };
}

export const ColorPaletteEditor = ({ initialValue, onChange }: ColorPaletteEditorProps) => {
    const [primary, setPrimary] = useState<ColorState>(() => getColorState(initialValue?.primary));
    const [secondary, setSecondary] = useState<ColorState>(() => getColorState(initialValue?.secondary));
    const [neutral, setNeutral] = useState<ColorState>(() => getColorState(initialValue?.neutral));
    const [background, setBackground] = useState<ColorState>(() => getColorState(initialValue?.background));

    useUpdateEffect(() => {
        if (!primary.isValid || !secondary.isValid || !neutral.isValid || !background.isValid) {
            onChange(undefined);
            return;
        }

        const newPalette: ColorPalette = {};

        if (primary.value) {
            newPalette.primary = primary.value;
        }

        if (secondary.value) {
            newPalette.secondary = secondary.value;
        }

        if (neutral.value) {
            newPalette.neutral = neutral.value;
        }

        if (background.value) {
            newPalette.background = background.value;
        }

        onChange(newPalette);
    }, [primary, secondary, neutral, background, onChange]);

    return (
        <Control spacing="compact">
            <ColorInput
                label="Primary"
                value={primary.value}
                isValid={primary.isValid}
                onChange={(value, isValid) => setPrimary({ value, isValid })}
            />
            <ColorInput
                label="Secondary"
                value={secondary.value}
                isValid={secondary.isValid}
                onChange={(value, isValid) => setSecondary({ value, isValid })}
            />
            <ColorInput
                label="Neutral"
                value={neutral.value}
                isValid={neutral.isValid}
                onChange={(value, isValid) => setNeutral({ value, isValid })}
            />
            <ColorInput
                label="Background"
                value={background.value}
                isValid={background.isValid}
                onChange={(value, isValid) => setBackground({ value, isValid })}
            />
        </Control>
    );
};
