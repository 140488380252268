import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NavTab, NavTabItem } from '@cimpress/react-components';
import { type ProblemSpaceRoutes, routes, routesByProblemSpace } from '../routes';
import { SubHeaderNavTab } from './SubHeaderNavTab';

import classes from './ProblemSpaceNavigation.module.css';

export const ProblemSpaceNavigation = () => {
    const [selectedProblemSpace, setSelectedProblemSpace] = useState<ProblemSpaceRoutes>(routesByProblemSpace[0]);
    const { pathname } = useLocation();
    const currentRoute = pathname.substring(1);

    useEffect(() => {
        const currentRouteDefinition = routes.find((route) => route.href === currentRoute);
        if (currentRouteDefinition) {
            setSelectedProblemSpace(
                routesByProblemSpace.find((problemSpace) => problemSpace.name === currentRouteDefinition.problemSpace)!,
            );
        }
    }, [currentRoute]);

    return (
        <>
            <NavTab className={classes.navigation}>
                {routesByProblemSpace.map((problemSpace) => (
                    <NavTabItem key={problemSpace.name} active={problemSpace === selectedProblemSpace}>
                        <button
                            onClick={() => {
                                setSelectedProblemSpace(problemSpace);
                            }}
                        >
                            {problemSpace.name}
                        </button>
                    </NavTabItem>
                ))}
            </NavTab>

            <SubHeaderNavTab
                className={classes.subNavigation}
                routes={selectedProblemSpace.routes}
                currentRoute={currentRoute}
            />
        </>
    );
};
