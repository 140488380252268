import type { PanelProperties } from '../types';

function parseDimension(dimensionWithUnit: string): string {
    const dimensionRegex = /([0-9.]*)([a-zA-Z]*)/;
    const dimension = dimensionWithUnit.match(dimensionRegex);

    return dimension ? `${parseInt(dimension[1])}${dimension[2]}` : '';
}

export function dimensionsToString(panels: PanelProperties[], panelIndex: number): string {
    const dimensions = panels[panelIndex];

    return dimensions ? `${parseDimension(dimensions.width)} x ${parseDimension(dimensions.height)}` : '';
}
