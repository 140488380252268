import { Spinner } from '@cimpress/react-components';

import classes from '../PreviewCard.module.css';

interface LoadingIndicatorProps {
    label?: string;
}

export const LoadingIndicator = ({ label }: LoadingIndicatorProps) => (
    <div className={classes.indicator}>
        <Spinner />

        <span className={classes.indicatorLabel}>{label}</span>
    </div>
);
