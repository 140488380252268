import type { ReactNode } from 'react';
import { Content } from '../layout';
import { type Card, PreviewCard } from './previewCard';

export const PreviewRow = (...cards: Card[]): Card[] => [...cards];

export const PreviewRows = (...rows: Card[][]): Card[][] => [...rows];

interface PreviewSectionProps {
    introduction?: string;
    showIntro: boolean;
    rows: Card[][];
    sticky?: boolean;
    footer?: ReactNode;
}

export const PreviewSection = ({ introduction, showIntro, rows, sticky = true, footer }: PreviewSectionProps) => {
    return (
        <Content.Card size={showIntro ? 'small' : 'large'} sticky={sticky}>
            {showIntro
                ? introduction
                : rows.map((row, index) => (
                      <Content.Section key={index}>
                          {row.map((card) => (
                              <PreviewCard key={card.title} {...card} />
                          ))}
                      </Content.Section>
                  ))}

            {footer}
        </Content.Card>
    );
};
