import { useEffect, useState } from 'react';
import { TabCard, Alert, Select, TextField } from '@cimpress/react-components';
import { InputJsonSelector } from '../Components/common/InputJsonSelector';
import {
    CardBuilder,
    Control,
    Culture,
    Page,
    PreviewRow,
    PreviewRows,
    PreviewSection,
    SurfaceSelector,
    useGenerateParams,
} from '../Components';
import { getCompositionGenerateUrl, getPhotoSceneUrl, getProductNameBasedOnSku } from '../api';
import { DEFAULT_PANEL_SELECTOR_OPTION, NONE_SELECTOR_OPTION } from '../constants';
import predefinedComponents from '../data/generatePrediefinedComponents.json';
import { CustomSelector, type SelectorOption } from '../Components/common';
import { isUrl, useCancelableEffect } from '../tools';
import { fullbleedSourceDocuments } from '../data/generateFullbleedDocuments';
import presetSurfaceUrls from '../data/generateSurfaceUrls.json';
import presetSurfaces from '../data/generateSurfaceJson.json';
import prodsAndOptions from '../data/prodandoptions.json';

const SKU_REGEX = new RegExp(/^[A-Z]{3,3}-[A-Z0-9]+$/g);
const CALCULATED_SURFACE_SETS_BASE_URL = 'https://surface.products.cimpress.io/v3/calculatedsurfacesets';

const CULTURE_VALUE_OPTIONS = Object.values(Culture).map((culture) => {
    return { label: culture, value: culture };
});

// Extracts the SKU-like part of the URL
function getSkuFromSurfaceSpecUrl(url: string) {
    const surfaceSpecParts = url.split('?')[0].split('/');
    return surfaceSpecParts.find((x) => SKU_REGEX.test(x))!;
}

function findFullfillmentInfoForProductKey(key?: string) {
    if (!key) {
        return undefined;
    }
    const match = prodsAndOptions.find((x) => x.PRODUCT_KEY === key);
    return match ? match.FULFILLMENT_SKU : undefined;
}

function convertToCalculatedSurfaceSpecUrl(source: string) {
    if (source.startsWith(CALCULATED_SURFACE_SETS_BASE_URL)) {
        return source;
    }
    const originalSku = getSkuFromSurfaceSpecUrl(source);
    const replacementSku = findFullfillmentInfoForProductKey(originalSku);
    if (!replacementSku) {
        return source;
    }
    // Break down the original
    const parts = source.split('&');
    const optionsPart = parts.find((x) => x.startsWith('optionSelections='));
    if (!optionsPart) {
        return source;
    }
    const variables = optionsPart.replace('optionSelections=', '');
    return `${CALCULATED_SURFACE_SETS_BASE_URL}/${replacementSku}?variables=${variables}`;
}

export const GeneratePage = () => {
    const {
        apiKey,
        assets,
        culture,
        dpiThreshold,
        panels,
        productName,
        projection,
        sourceDocumentUrl,
        surfaceSpecification,
        surfaceSpecificationUrl,
        setAssets,
        setCulture,
        setDpiThreshold,
        setPanels,
        setProductName,
        setProjection,
        setSourceDocumentUrl,
        setSurfaceSpecification,
        setSurfaceSpecificationUrl,
    } = useGenerateParams();
    const [originRow, setOriginRow] = useState(PreviewRow());
    const [resultRow, setResultRow] = useState(PreviewRow());
    const [hasPageLoaded, setHasPageLoaded] = useState(false);
    const [isDocumentAssetAlertInfo, setIsDocumentAssetAlertInfo] = useState(true);

    const panelsValueOptions = [
        { label: 'First', value: 'first' },
        { label: 'All', value: 'all' },
    ];

    useEffect(() => {
        if (!!assets !== !!sourceDocumentUrl && (surfaceSpecificationUrl || surfaceSpecification)) {
            setIsDocumentAssetAlertInfo(true);

            if (sourceDocumentUrl) {
                const fullBleedCard = CardBuilder.ofType('documentUrl')
                    .withTitle('Source Document')
                    .withData({ documentUrl: sourceDocumentUrl, backgroundColor: 'FFFFFF' })
                    .build();
                setOriginRow(PreviewRow(fullBleedCard));
            } else {
                setOriginRow(PreviewRow());
            }

            const resultCard = CardBuilder.ofType('documentUrl')
                .withTitle('Result Document')
                .withLoader(async ({ signal }) => {
                    const calculatedSurfaceSpecUrl = surfaceSpecificationUrl
                        ? convertToCalculatedSurfaceSpecUrl(surfaceSpecificationUrl)
                        : undefined;

                    const photoSceneUrl = calculatedSurfaceSpecUrl
                        ? ((await getPhotoSceneUrl(calculatedSurfaceSpecUrl, signal)) ?? '')
                        : undefined;

                    return {
                        documentUrl: getCompositionGenerateUrl({
                            assets,
                            surfaceSpecificationUrl,
                            sourceDocumentUrl,
                            surfaceSpecification,
                            projection: projection.value,
                            productName,
                            panels: panels.value,
                            dpiThreshold,
                            apiKey,
                            culture: culture.value,
                        }),
                        photoSceneUrl,
                        surfaceUrl: calculatedSurfaceSpecUrl,
                        projection: projection.value,
                        backgroundColor: 'FFFFFF',
                    };
                })
                .build();
            setResultRow(PreviewRow(resultCard));
        } else {
            if (assets && sourceDocumentUrl) {
                setIsDocumentAssetAlertInfo(false);
            }
        }
    }, [
        assets,
        projection,
        surfaceSpecificationUrl,
        sourceDocumentUrl,
        productName,
        apiKey,
        panels,
        dpiThreshold,
        culture,
        surfaceSpecification,
    ]);

    useCancelableEffect(
        (signal) => {
            (async () => {
                if (surfaceSpecificationUrl) {
                    const extractedProductSku = getSkuFromSurfaceSpecUrl(surfaceSpecificationUrl);
                    const productSku = findFullfillmentInfoForProductKey(extractedProductSku) || extractedProductSku;
                    const productName = (await getProductNameBasedOnSku(productSku, signal)) ?? '';
                    handleProductNameChange(productName);
                }
            })();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [surfaceSpecificationUrl],
    );

    useEffect(() => {
        setHasPageLoaded(true);
    }, []);

    function handleProductNameChange(productName: string) {
        // We are not calling this setProductName for the first time when page loads because we want to keep value in url params.
        // If we call this when page loads this will override value in URL param.
        if (hasPageLoaded) {
            setProductName(productName);
        }
    }

    const onChangeSelectPanels = (selected: SelectorOption | null | undefined) => {
        if (!selected) {
            return;
        }
        setPanels(selected ?? DEFAULT_PANEL_SELECTOR_OPTION);
    };
    const onChangeSelectCulture = (selected: SelectorOption | null | undefined) => {
        if (!selected) {
            return;
        }
        setCulture(selected);
    };

    const handleDpiThresholdChange = (dpiThreshold: string | undefined | null) => {
        if (!dpiThreshold) {
            return;
        }
        setDpiThreshold(Number.parseFloat(dpiThreshold));
    };

    const docUrlTabContent = (
        <Control>
            <Control.Title>Source Document</Control.Title>
            <CustomSelector
                selectorLabel="Document"
                textfieldLabel="Document URL"
                data={fullbleedSourceDocuments}
                value={sourceDocumentUrl}
                setValue={setSourceDocumentUrl}
                validator={isUrl}
            />
        </Control>
    );
    const assetJsonTabContent = (
        <Control>
            <Control.Title>Assets</Control.Title>
            <InputJsonSelector
                rows={25}
                label="Assets"
                initialValue={assets}
                onChange={setAssets}
                predefinedValues={predefinedComponents}
            />
        </Control>
    );

    const tabs = [
        {
            name: 'Document URL',
            block: docUrlTabContent,
        },
        {
            name: 'Assets JSON',
            block: assetJsonTabContent,
        },
    ];

    const handleTabChanged = (_: any, selectedKey: number) => {
        if (selectedKey === 0) {
            // doc URL variant
            setAssets(undefined);
            setSourceDocumentUrl(sourceDocumentUrl);
        } else {
            // asset JSON variant
            setAssets(assets);
            setSourceDocumentUrl('');
        }
    };

    return (
        <Page>
            <Page.Title>Generate</Page.Title>

            <Page.Controls>
                <Control>
                    <Control.Title>Surface Selection</Control.Title>
                    <SurfaceSelector
                        surfaceSpecificationUrl={surfaceSpecificationUrl}
                        setSurfaceSpecificationUrl={setSurfaceSpecificationUrl}
                        surfaceSpecification={surfaceSpecification}
                        setSurfaceSpecification={setSurfaceSpecification}
                        projection={projection}
                        setProjection={(projection) => setProjection(projection ?? NONE_SELECTOR_OPTION)}
                        presetSurfaceUrlsFromPage={presetSurfaceUrls}
                        presetSurfaceJsonFromPage={presetSurfaces}
                        showProjectionSelector
                    />
                </Control>

                <Control>
                    <Control.Title>Product Name</Control.Title>
                    <TextField
                        label="Product Name"
                        onChange={(e) => handleProductNameChange(e.target.value)}
                        value={productName}
                    />
                </Control>

                <Control>
                    <Alert
                        status={isDocumentAssetAlertInfo ? 'info' : 'danger'}
                        dismissible={false}
                        message="Provide either source document url or json with assets."
                    />

                    <TabCard tabs={tabs} onSelect={handleTabChanged} />
                </Control>

                <Control>
                    <Control.Title>Minimum required DPI for an image</Control.Title>
                    <TextField
                        label="DPI Threshold"
                        type="number"
                        onChange={(e) => handleDpiThresholdChange(e.target.value)}
                        value={dpiThreshold}
                    />
                </Control>

                <Control>
                    <Control.Title>Panels</Control.Title>

                    <Select
                        onChange={onChangeSelectPanels}
                        value={panels}
                        options={panelsValueOptions}
                        label="Panels"
                    />

                    <Alert
                        status="info"
                        dismissible={false}
                        message="Panels property is only applicable for assets with only logo or only photo."
                    />
                </Control>

                <Control>
                    <Control.Title>Culture</Control.Title>

                    <Select
                        onChange={onChangeSelectCulture}
                        value={culture}
                        options={CULTURE_VALUE_OPTIONS}
                        label="Culture"
                    />
                </Control>
            </Page.Controls>

            <Page.Content>
                <PreviewSection
                    introduction={
                        'This is a page to test the generate endpoint. Fill in the assets and surface URL to see the generate result.'
                    }
                    showIntro={!assets === !sourceDocumentUrl}
                    rows={PreviewRows(originRow, resultRow)}
                />
            </Page.Content>
        </Page>
    );
};
