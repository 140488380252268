import { parseColor } from '@mcp-artwork/color-converter';

export function isValidColorState(color: string): boolean {
    // It is valid to leave the color field blank
    if (color === '') {
        return true;
    }

    if (color.trim() === '') {
        return false;
    }

    const parsedColor = parseColor(color);
    return !!parsedColor;
}
