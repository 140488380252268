import { Button } from '@cimpress/react-components';
import { SketchPicker } from 'react-color';
import { useState } from 'react';
import { CloseButton } from '../common';
import { Control } from '../layout';

import classes from './ColorPickerControl.module.css';

interface ColorPickerControlProps {
    color: string;
    setColor: (colors: string) => void;
}

export const ColorPickerControl = ({ color, setColor }: ColorPickerControlProps) => {
    const [showColorPicker, SetShowColorPicker] = useState(false);

    const addColor = () => {
        const newColor = '#ffffff';
        setColor(newColor);
        SetShowColorPicker(true);
    };

    const removeColor = () => {
        const newColor = '';
        setColor(newColor);
        SetShowColorPicker(false);
    };

    const updateColorPicker = (value: string) => {
        setColor(value);
    };

    return showColorPicker ? (
        <Control>
            <div className={classes.wrapper}>
                <SketchPicker width="65%" color={color} onChangeComplete={(e) => updateColorPicker(e.hex)} />
                <CloseButton onClick={removeColor} />
            </div>
        </Control>
    ) : (
        <Control inline>
            <Button onClick={addColor}>Add Surface Substrate Color</Button>
        </Control>
    );
};
