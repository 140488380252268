import { Alert } from '@cimpress/react-components';
import { useEffect, useState } from 'react';
import {
    CardBuilder,
    Control,
    CustomSelector,
    Page,
    PreviewRow,
    PreviewRows,
    PreviewSection,
    SurfaceSelector,
} from '../Components';
import { ColorPickerControl } from '../Components/AdjustForSurface/ColorPickerControl';
import { useAdjustForSurfaceParams } from '../Components/AdjustForSurface/useAdjustForSurfaceParams';
import { useSampleDocuments } from '../Components/common/useSampleDocuments';
import { getAdjustForSurfaceUrl, getPhotoSceneUrl } from '../api';
import { NONE_SELECTOR_OPTION } from '../constants';
import { getUrl, isUrl } from '../tools';

const intro = `This is the page to test our adjust for surface endpoint.\nFill in or select a document url and surface to get started.`;

export const AdjustForSurfacePage = () => {
    const predefinedDocuments = useSampleDocuments('adjustForSurface_source_documents.json');

    const adjustForSurfaceParams = useAdjustForSurfaceParams();
    const [sourceRow, setSourceRow] = useState(PreviewRow());
    const [resultRow, setResultRow] = useState(PreviewRow());

    useEffect(() => {
        if (adjustForSurfaceParams.document) {
            const sourceCard = CardBuilder.ofType('documentUrl')
                .withTitle('Source Document')
                .withData({ documentUrl: adjustForSurfaceParams.document })
                .build();
            setSourceRow(PreviewRow(sourceCard));
        }
    }, [adjustForSurfaceParams.document, setSourceRow]);

    useEffect(() => {
        if (adjustForSurfaceParams.document && adjustForSurfaceParams.surfaceSpecificationUrl) {
            const resultCard = CardBuilder.ofType('documentUrl')
                .withTitle(
                    adjustForSurfaceParams.targetSubstrateColor
                        ? 'Result Document With Substrate Color'
                        : 'Result Document',
                )
                .withLoader(async ({ signal }) => {
                    const photoSceneUrl =
                        (await getPhotoSceneUrl(adjustForSurfaceParams.surfaceSpecificationUrl, signal)) ?? '';

                    return {
                        documentUrl:
                            getAdjustForSurfaceUrl(
                                adjustForSurfaceParams.document,
                                adjustForSurfaceParams.surfaceSpecificationUrl,
                                adjustForSurfaceParams.targetSubstrateColor,
                            ) ?? '',
                        photoSceneUrl,
                        surfaceUrl: adjustForSurfaceParams.surfaceSpecificationUrl,
                        backgroundColor: adjustForSurfaceParams.targetSubstrateColor
                            ? adjustForSurfaceParams.targetSubstrateColor.substring(1)
                            : 'FFFFFF',
                    };
                })
                .build();
            setResultRow(PreviewRow(resultCard));
        }
    }, [
        adjustForSurfaceParams.document,
        adjustForSurfaceParams.surfaceSpecificationUrl,
        adjustForSurfaceParams.targetSubstrateColor,
        setResultRow,
    ]);

    const setDocumentUrl = (docUrl: string) => {
        adjustForSurfaceParams.setDocument(getUrl(docUrl) ?? docUrl);
    };

    return (
        <Page>
            <Page.Title>Adapt to Product Surface</Page.Title>

            <Page.Controls>
                <Control>
                    <Control.Title>Source Document or Template</Control.Title>
                    <CustomSelector
                        selectorLabel={'Document or template'}
                        textfieldLabel={'Document URL or Template URL '}
                        data={predefinedDocuments}
                        value={adjustForSurfaceParams.document ?? ''}
                        setValue={setDocumentUrl}
                        validator={isUrl}
                    />
                </Control>

                <Control>
                    <Control.Title>Surface Selection</Control.Title>
                    <SurfaceSelector
                        surfaceSpecificationUrl={adjustForSurfaceParams.surfaceSpecificationUrl}
                        setSurfaceSpecificationUrl={adjustForSurfaceParams.setSurfaceSpecificationUrl}
                        surfaceSpecification={adjustForSurfaceParams.surfaceSpecification}
                        projection={NONE_SELECTOR_OPTION}
                        setProjection={() => {}}
                        showProjectionSelector={false}
                    />
                </Control>

                <ColorPickerControl
                    color={adjustForSurfaceParams.targetSubstrateColor}
                    setColor={adjustForSurfaceParams.setTargetSubstrateColor}
                />

                <Alert
                    status="info"
                    dismissible={false}
                    message="Previewing the embroidery result may take some time."
                />
            </Page.Controls>

            <Page.Content>
                <PreviewSection
                    introduction={intro}
                    showIntro={!adjustForSurfaceParams.document}
                    rows={PreviewRows(sourceRow, resultRow)}
                />
            </Page.Content>
        </Page>
    );
};
