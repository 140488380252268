import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TextField } from '@cimpress/react-components';
import { getFontSwapUrl } from '../api';
import {
    type Card,
    CardBuilder,
    Control,
    CustomSelector,
    Page,
    PreviewRow,
    PreviewRows,
    PreviewSection,
} from '../Components';
import { isUrl, useDebounce } from '../tools';
import { useSampleDocuments } from '../Components/common/useSampleDocuments';

const sourceDocumentUrlParamName = 'sourceDocumentUrl';
const sourceFontParamName = 'sourceFont';
const targetFontParamName = 'targetFont';

const intro =
    'This is the page to test our font swap endpoint.\nFill in the source document URL, source font and target font to see the swap result.';

export function FontSwapPage() {
    const predefinedDocuments = useSampleDocuments('fontSwap_source_documents.json');

    const [searchParams, setSearchParams] = useSearchParams();
    const sourceDocumentUrlParam = searchParams.get(sourceDocumentUrlParamName);
    const sourceFontParam = searchParams.get(sourceFontParamName);
    const targetFontParam = searchParams.get(targetFontParamName);

    const [sourceDocumentUrl, setSourceDocumentUrl] = useState(sourceDocumentUrlParam ?? undefined);
    const [sourceFont, setSourceFont] = useState(sourceFontParam ?? undefined);
    const [targetFont, setTargetFont] = useState(targetFontParam ?? undefined);

    const debouncedSourceDocumentUrl = useDebounce(sourceDocumentUrl, 300);
    const debouncedSourceFont = useDebounce(sourceFont, 300);
    const debouncedTargetFont = useDebounce(targetFont, 300);

    const [sourceRow, setSourceRow] = useState<Card[]>([]);
    const [resultRow, setResultRow] = useState<Card[]>([]);

    useEffect(() => {
        const searchParamsObj: Record<string, string> = {};

        if (sourceDocumentUrl) {
            searchParamsObj[sourceDocumentUrlParamName] = sourceDocumentUrl;
        }

        if (sourceFont) {
            searchParamsObj[sourceFontParamName] = sourceFont;
        }

        if (targetFont) {
            searchParamsObj[targetFontParamName] = targetFont;
        }

        setSearchParams(searchParamsObj, { replace: true });
    }, [sourceDocumentUrl, sourceFont, targetFont, setSearchParams]);

    useEffect(() => {
        if (!debouncedSourceDocumentUrl) {
            setSourceRow(PreviewRow());
            return;
        }

        const sourceCard = CardBuilder.ofType('documentUrl')
            .withTitle('Source Document')
            .withData({ documentUrl: debouncedSourceDocumentUrl })
            .build();

        setSourceRow(PreviewRow(sourceCard));
    }, [debouncedSourceDocumentUrl]);

    useEffect(() => {
        if (!debouncedSourceDocumentUrl || !debouncedSourceFont || !debouncedTargetFont) {
            setResultRow(PreviewRow());
            return;
        }

        const resultUrl = getFontSwapUrl(debouncedSourceDocumentUrl, debouncedSourceFont, debouncedTargetFont) ?? '';

        const resultCard = CardBuilder.ofType('documentUrl')
            .withTitle('Result Document')
            .withData({ documentUrl: resultUrl, backgroundColor: 'FFFFFF' })
            .build();

        setResultRow(PreviewRow(resultCard));
    }, [debouncedSourceDocumentUrl, debouncedSourceFont, debouncedTargetFont]);

    return (
        <Page>
            <Page.Title>Font Swap</Page.Title>

            <Page.Controls>
                <Control>
                    <Control.Title>Source Document or Template</Control.Title>
                    <CustomSelector
                        selectorLabel="Document (or template)"
                        textfieldLabel="Document URL, Template URL or Template Token"
                        data={predefinedDocuments}
                        value={sourceDocumentUrl}
                        setValue={setSourceDocumentUrl}
                        validator={isUrl}
                    />
                </Control>

                <Control>
                    <Control.Title>Source Font</Control.Title>
                    <TextField
                        label="Source font"
                        onChange={(e) => setSourceFont(e.target.value)}
                        type="text"
                        value={sourceFont}
                        helpText="All text fields from the source document that use this font will be swapped."
                    />
                </Control>

                <Control>
                    <Control.Title>Target Font</Control.Title>
                    <TextField
                        label="Target font"
                        onChange={(e) => setTargetFont(e.target.value)}
                        type="text"
                        value={targetFont}
                    />
                </Control>
            </Page.Controls>

            <Page.Content>
                <PreviewSection
                    introduction={intro}
                    showIntro={!debouncedSourceDocumentUrl}
                    rows={PreviewRows(sourceRow, resultRow)}
                />
            </Page.Content>
        </Page>
    );
}
