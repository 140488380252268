import { Checkbox } from '@cimpress/react-components';

interface PhotoSceneToggleProps {
    value: boolean;
    onChange: (value: boolean) => void;
}

export const PhotoSceneToggle = ({ value, onChange }: PhotoSceneToggleProps) => (
    <Checkbox label="Photo Scene" checked={value} onChange={(event) => onChange(event.target.checked)} />
);
