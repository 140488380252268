import type { ReactNode } from 'react';
import classNames from 'classnames';
import { Card } from '@cimpress/react-components';

import classes from './ContentCard.module.css';

interface ContentCardProps {
    children: ReactNode | ReactNode[];
    size?: 'small' | 'large';
    sticky?: boolean;
}

export const ContentCard = ({ children, size = 'small', sticky = true }: ContentCardProps) => (
    <Card
        className={classNames(classes.contentCard, classes[`${size}Size`], {
            [classes.sticky]: sticky,
        })}
    >
        {children}
    </Card>
);
