import { uploadPrintUrlAndGetPreviewUrl } from '../api';

export async function getPreviewUrl(url: string, signal?: AbortSignal): Promise<string> {
    try {
        const res = await fetch(url, { signal });
        const isPdf = res.headers.get('content-type')?.includes('pdf');

        return isPdf ? await uploadPrintUrlAndGetPreviewUrl(url, signal) : url;
    } catch (error) {
        if (signal?.aborted) {
            throw error;
        }

        console.log(`An error occurred while fetching preview URL: ${error}`);
        return url;
    }
}
