import type { ReactNode } from 'react';
import classNames from 'classnames';

import classes from './Control.module.css';

interface ControlProps {
    children: ReactNode | ReactNode[];
    className?: string;
    spacing?: 'none' | 'ultraCompact' | 'compact' | 'standard' | 'wide';
    show?: boolean;
    inline?: boolean;
    horizontal?: boolean;
}

const Control = ({
    children,
    className,
    spacing = 'standard',
    show = true,
    inline = false,
    horizontal = false,
}: ControlProps) => (
    <div
        className={classNames(
            classes.control,
            classes[`${spacing}Spacing`],
            {
                [classes.visible]: show,
                [classes.inline]: inline,
                [classes.horizontal]: horizontal,
            },
            className,
        )}
    >
        {children}
    </div>
);

const ControlTitle = ({ children }: { children: string }) => <div className={classes.title}>{children}</div>;

Control.Title = ControlTitle;

export { Control };
