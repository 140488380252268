import type { SelectorOption } from '../Components';

export const fullbleedSourceDocuments: SelectorOption[] = [
    {
        label: 'Sample document 1',
        value: 'https://storage.documents.cimpress.io/v3/documents/6c461b13-69e8-462a-98e8-24ccbd529cf0/revisions/72415e3a-c6f2-4813-ace0-29b488d6d43e',
    },
    {
        label: 'Sample document 2',
        value: 'https://storage.documents.cimpress.io/v3/documents/56b54064-02f1-41d4-9f27-e112b70d2492/revisions/38f68e15-c2f2-4ee0-ad00-54b84bf4ce23',
    },
];
