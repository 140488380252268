import { type DragEvent, type ReactNode, useRef, useState } from 'react';
import classNames from 'classnames';

import classes from './FileDropArea.module.css';

interface FileDropAreaProps {
    label: ReactNode;
    allowedFileTypes: string[];
    onFileDrop: (file: File) => void;
}

export const FileDropArea = ({ label, allowedFileTypes, onFileDrop }: FileDropAreaProps) => {
    const [isDraggingFile, setIsDraggingFile] = useState(false);
    const [isDraggedFileValid, setIsDraggedFileValid] = useState(false);

    // Drag events also fire on child elements,
    // which causes blinking when moving the cursor while dragging.
    // To counteract that, we keep track of how many dragenter events happened without a corresponding dragleave,
    // and hide the overlay only when we're at 0 again.
    const dragCount = useRef<number>(0);

    const onDragEnter = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        dragCount.current++;

        const dragItem = event.dataTransfer.items[0];
        if (dragItem) {
            setIsDraggingFile(true);
            setIsDraggedFileValid(dragItem.kind === 'file' && allowedFileTypes.includes(dragItem.type));
        }
    };

    const onDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const onDragLeave = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        dragCount.current--;

        if (dragCount.current <= 0) {
            setIsDraggingFile(false);
        }
    };

    const onDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        dragCount.current = 0;
        setIsDraggingFile(false);

        const file = event.dataTransfer.files.item(0);
        if (file) {
            onFileDrop(file);
        }
    };

    return (
        <div
            className={classNames(classes.dropArea, {
                [classes.validFileOver]: isDraggingFile && isDraggedFileValid,
                [classes.invalidFileOver]: isDraggingFile && !isDraggedFileValid,
            })}
            onDragEnter={onDragEnter}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
        >
            <div className={classes.label}>{label}</div>
        </div>
    );
};
