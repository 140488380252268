import axios from 'axios';
import { AUTHORIZATION_HEADERS } from './constants';

export const uploadPrintUrlAndGetPreviewUrl = async (url: string, signal?: AbortSignal): Promise<string> => {
    const uploadApi = `https://uploads.documents.cimpress.io/v1/uploads?deleteAfterDays=1&url=${url}&process={
        "type": "image"
      }`;

    try {
        const result = await axios.post<[{ uploadId: string }]>(uploadApi, null, {
            headers: { ...AUTHORIZATION_HEADERS, 'Content-Type': 'multipart/form-data' },
            signal,
        });
        const imageId = result.data[0].uploadId;

        return `https://uploads.documents.cimpress.io/v1/uploads/${imageId}?variant=preview`;
    } catch (error) {
        if (signal?.aborted) {
            throw error;
        }

        console.error(
            `An error occurred while uploading to "https://uploads.documents.cimpress.io/v1/uploads": ${error}`,
        );
        throw error;
    }
};
