import { Link } from 'react-router-dom';
import { NavTab, NavTabItem } from '@cimpress/react-components';
import type { Route } from '../routes';

interface SubHeaderNavTabProps {
    className?: string;
    routes: Route[];
    currentRoute: string;
}

export const SubHeaderNavTab = ({ className, routes, currentRoute }: SubHeaderNavTabProps) => {
    return (
        <NavTab className={className}>
            {routes.map(({ title, href }) => (
                <NavTabItem key={href} active={href === currentRoute}>
                    <Link to={href}>{title}</Link>
                </NavTabItem>
            ))}
        </NavTab>
    );
};
