import { useEffect, useState } from 'react';
import samplesS3Axios from '../../api/samplesS3Axios';
import type { SelectorOption } from './types';

export const useSampleDocuments = (fileName: string): SelectorOption[] => {
    const [documents, setDocuments] = useState<SelectorOption[]>([]);
    useEffect(() => {
        samplesS3Axios
            .get<SelectorOption[]>(fileName)
            .then((res) => setDocuments(res.data))
            .catch((err) => console.log(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return documents;
};
