import { auth } from '../Auth';

export const BASE_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

export const AUTHORIZATION_HEADERS = {
    ...BASE_HEADERS,
    Authorization: `Bearer ${auth.getAccessToken()}`,
};
