import { Card, Select, Checkbox, TextField, Tooltip } from '@cimpress/react-components';
import { useEffect, useState, useCallback, useRef, type ChangeEvent } from 'react';
import defaultPhysicalities from '../../data/products.json';
import type { SelectorOption } from '../common';
import { Control } from '../layout';
import type { Physicality } from './types';

import classes from './ResizeDimensionsControl.module.css';

const supportedUnits: SelectorOption[] = [
    { label: 'mm', value: 'mm' },
    { label: 'cm', value: 'cm' },
    { label: 'dm', value: 'dm' },
    { label: 'm', value: 'm' },
    { label: 'pt', value: 'pt' },
    { label: 'twip', value: 'twip' },
];

interface ResizeDimensionsControlProps {
    physicalities: Physicality[];
    customPhysicality?: Physicality;
    setPhysicalities: (physicalities: Physicality[]) => void;
    setCustomPhysicality: (physicality: Physicality | undefined) => void;
}

export const ResizeDimensionsControl = ({
    physicalities,
    customPhysicality,
    setPhysicalities,
    setCustomPhysicality,
}: ResizeDimensionsControlProps) => {
    const dimensionRegex = /([0-9]*)([a-zA-Z]*)/;

    const height = customPhysicality ? +dimensionRegex.exec(customPhysicality['height'])![1] : 100;
    const width = customPhysicality ? +dimensionRegex.exec(customPhysicality['width'])![1] : 100;

    const unit: SelectorOption = customPhysicality
        ? {
              label: dimensionRegex.exec(customPhysicality['width'])![2],
              value: dimensionRegex.exec(customPhysicality['width'])![2],
          }
        : supportedUnits[0];

    const resizePhysicalitiesRef = useRef<Physicality[]>([]);
    resizePhysicalitiesRef.current = physicalities;

    const [customSelected, setCustomSelected] = useState(!!customPhysicality);
    const [customWidth, setCustomWidth] = useState(width);
    const [customHeight, setCustomHeight] = useState(height);
    const [customUnit, setCustomUnit] = useState(unit);

    const customOnChange = {
        checkBox: (e: ChangeEvent<HTMLInputElement>) => {
            if (!e.target.checked) {
                setCustomPhysicality(undefined);
            }

            setCustomSelected(e.target.checked);
        },
        width: (e: ChangeEvent<HTMLInputElement>) => {
            setCustomWidth(+e.target.value);
        },
        height: (e: ChangeEvent<HTMLInputElement>) => {
            removePhysicality('Custom');
            setCustomHeight(+e.target.value);
        },
        unit: (newUnit: SelectorOption | null | undefined) => {
            if (newUnit) {
                setCustomUnit(newUnit);
            }
        },
    };
    const addPhysicality = useCallback(
        (physicality: Physicality) => {
            setPhysicalities([...resizePhysicalitiesRef.current, physicality]);
        },
        [setPhysicalities],
    );

    const removePhysicality = (name: string) => {
        const newArr = resizePhysicalitiesRef.current.filter((value) => value.name !== name);

        setPhysicalities(newArr);
    };

    const getCustomPhysicality = useCallback((): Physicality => {
        return {
            name: 'Custom',
            width: `${customWidth}${customUnit.value}`,
            height: `${customHeight}${customUnit.value}`,
        };
    }, [customWidth, customHeight, customUnit]);

    useEffect(() => {
        if (customSelected) {
            setCustomPhysicality(getCustomPhysicality());
        }
    }, [customSelected, setCustomPhysicality, getCustomPhysicality]);

    return (
        <>
            <Control>
                <Control.Title>Custom Dimensions</Control.Title>
                <Card>
                    <div className={classes.customDimensions}>
                        <Checkbox onChange={customOnChange.checkBox} checked={customSelected} label="" />

                        <TextField
                            label="Width"
                            onChange={(e) => customOnChange.width(e)}
                            type="number"
                            value={customWidth}
                        />

                        <TextField
                            label="Height"
                            onChange={(e) => customOnChange.height(e)}
                            type="number"
                            value={customHeight}
                        />

                        <Select
                            label="Unit"
                            onChange={(e) => customOnChange.unit(e)}
                            options={supportedUnits}
                            value={customUnit}
                        />
                    </div>
                </Card>
            </Control>

            <Control>
                <Control.Title>Preset Dimensions</Control.Title>
                <Card>
                    {defaultPhysicalities.map((dp) => (
                        <Checkbox
                            id={dp.name}
                            key={dp.name}
                            label={
                                <Tooltip direction="right" contents={`${dp.width} x ${dp.height}`}>
                                    <div>{dp.name}</div>
                                </Tooltip>
                            }
                            checked={physicalities.findIndex((v) => v.name === dp.name) >= 0}
                            onChange={(event) =>
                                event.target.checked ? addPhysicality(dp) : removePhysicality(dp.name)
                            }
                        />
                    ))}
                </Card>
            </Control>
        </>
    );
};
