import axios from 'axios';
import { AUTHORIZATION_HEADERS } from './constants';

export const uploadJsonDocumentAndGetDocumentUrl = async (jsonDocument: any, signal?: AbortSignal): Promise<string> => {
    const storageApi = 'https://storage.documents.cimpress.io/v3/documents';

    try {
        const result = await axios.post(storageApi, jsonDocument, {
            headers: { ...AUTHORIZATION_HEADERS, 'Content-Type': 'application/json' },
            signal,
        });
        const doucmentUrl = result.data._links.self.href;
        return doucmentUrl;
    } catch (error) {
        if (signal?.aborted) {
            throw error;
        }

        console.error(`An error occurred while uploading document: ${error}`);
        throw error;
    }
};
