import classNames from 'classnames';
import { FusionCanvas, type TextOptions } from '@rendering/fusion-react';
import { ErrorIndicator, LoadingIndicator, PanelSwitcher } from '../components';
import type { PreviewRendererProps } from '../types';
import { useLoadCardData } from '../useLoadCardData';
import classes from '../PreviewCard.module.css';
import { dimensionsToString } from './helpers';

const CANVAS_DIMENSION = { height: 300 };
const TEXT_OPTIONS: TextOptions = { rtextEnabled: false };

export const DocumentPreviewRenderer = ({ loader }: PreviewRendererProps<'document'>) => {
    const {
        data,
        isLoading,
        loadingLabel,
        hasError,
        errorMessage,
        panels,
        panelIndex,
        onPreviewLoaded,
        onPreviewError,
        onPanelIndexChange,
    } = useLoadCardData(loader);

    const handleFusionError = (error: unknown) => {
        if (error instanceof TypeError && error.message.includes('getContext')) {
            // Sometimes Fusion tries to render after its canvas is unmounted,
            // we don't want to show an error in that case
            return;
        }

        onPreviewError();
    };

    const document = data?.document;
    const panelId = panels[panelIndex]?.id;

    return (
        <>
            {isLoading && <LoadingIndicator label={loadingLabel} />}

            {hasError && <ErrorIndicator message={errorMessage} />}

            {!hasError && document && panelId && (
                <div className={classNames(classes.preview, { [classes.hidden]: isLoading })}>
                    <FusionCanvas
                        cimDoc={document}
                        dimension={CANVAS_DIMENSION}
                        textOptions={TEXT_OPTIONS}
                        selector={{ type: 'panel', id: panelId }}
                        onPaint={onPreviewLoaded}
                        onError={handleFusionError}
                        referrer=""
                    />
                    <span>{dimensionsToString(panels, panelIndex)}</span>
                </div>
            )}

            <PanelSwitcher panelCount={panels.length} panelIndex={panelIndex} onChange={onPanelIndexChange} />
        </>
    );
};
