export function getNumberParam(param: string | null, defaultValue: number): number {
    if (!param) {
        return defaultValue;
    }

    const parsedParam = parseFloat(param);

    if (Number.isNaN(parsedParam)) {
        return defaultValue;
    }

    return parsedParam;
}
