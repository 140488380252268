export const projectionMapper = (projection: string | undefined, panel: number): string => {
    switch (projection) {
        case '90_270_ODD_EVEN':
            return panel % 2 === 0 ? 'ROTATE_90' : 'ROTATE_270';

        case '90_270_EVEN_ODD':
            return panel % 2 === 0 ? 'ROTATE_270' : 'ROTATE_90';

        case '270_90_EVEN_ODD':
            return panel % 2 === 0 ? 'ROTATE_90' : 'ROTATE_270';

        case '270_90_ODD_EVEN':
            return panel % 2 === 0 ? 'ROTATE_270' : 'ROTATE_90';

        case 'ROTATE_90':
            return 'ROTATE_270';

        case 'ROTATE_270':
            return 'ROTATE_90';

        default:
            return '';
    }
};
