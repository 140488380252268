import { Spinner } from '@cimpress/react-components';
import type { CSSProperties, ReactNode } from 'react';

interface LoadingContainerProps {
    isLoading: boolean;
    children: ReactNode;
}

const LOADING_CONTAINER_STYLE: CSSProperties = {
    position: 'relative',
};

const LOADING_OVERLAY_STYLE: CSSProperties = {
    position: 'absolute',
    inset: '-5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backdropFilter: 'blur(4px)',
    WebkitBackdropFilter: 'blur(4px)',
    cursor: 'wait',
    zIndex: 10,
    backgroundColor: 'rgba(239, 243, 245, 0.7)' /* colors.silver from Component Library, with opacity 70% */,
};

export const LoadingContainer = ({ isLoading, children }: LoadingContainerProps) => {
    return (
        <div style={LOADING_CONTAINER_STYLE}>
            {children}
            {isLoading && (
                <div style={LOADING_OVERLAY_STYLE}>
                    <Spinner />
                </div>
            )}
        </div>
    );
};
