import { Card, Checkbox, Tooltip } from '@cimpress/react-components';
import { CustomSelector } from '../common';
import { isUrl } from '../../tools';
import { useSampleDocuments } from '../common/useSampleDocuments';
import { Control } from '../layout';
import { isSmallScreen } from '../../tools/isSmallScreen';

interface TransferCustomizationControlsProps {
    sourceUrl: string;
    setSourceUrl: (sourceUrl: string) => void;
    targetUrl: string;
    setTargetUrl: (targetUrl: string) => void;
    transferCustomerAssets: boolean;
    setTransferCustomerAssets: (value: boolean) => void;
    useSmartTextTransfer: boolean;
    setUseSmartTextTransfer: (value: boolean) => void;
}

export const TransferCustomizationControls = ({
    sourceUrl,
    setSourceUrl,
    targetUrl,
    setTargetUrl,
    transferCustomerAssets,
    setTransferCustomerAssets,
    useSmartTextTransfer,
    setUseSmartTextTransfer,
}: TransferCustomizationControlsProps) => {
    const sources = useSampleDocuments('transferCustomization_source_documents.json');
    const targets = useSampleDocuments('transferCustomization_target_documents.json');

    return (
        <>
            <Control>
                <Control.Title>Target Document or Template</Control.Title>
                <CustomSelector
                    selectorLabel="Document (or template)"
                    textfieldLabel="Document URL, Template URL or Template Token"
                    data={targets}
                    value={targetUrl}
                    setValue={setTargetUrl}
                    validator={isUrl}
                />
            </Control>

            <Control>
                <Control.Title>Source Document or Template</Control.Title>
                <CustomSelector
                    selectorLabel="Document (or template)"
                    textfieldLabel="Document URL, Template URL or Template Token"
                    data={sources}
                    value={sourceUrl}
                    setValue={setSourceUrl}
                    validator={isUrl}
                />
            </Control>

            <Control>
                <Control.Title>Transfer options</Control.Title>
                <Card>
                    <Checkbox
                        label={
                            <Tooltip
                                direction={isSmallScreen() ? 'bottom' : 'right'}
                                contents="A parameter to indicate whether smart text transfer should be used."
                            >
                                <div>Use smart text transfer</div>
                            </Tooltip>
                        }
                        checked={useSmartTextTransfer}
                        onChange={(event) => setUseSmartTextTransfer(event.target.checked)}
                    />
                    <Checkbox
                        label={
                            <Tooltip
                                direction={isSmallScreen() ? 'bottom' : 'right'}
                                contents="A parameter to indicate whether assets added by customer should be transferred from source to target. Default value is true."
                            >
                                <div>Transfer customer assets</div>
                            </Tooltip>
                        }
                        checked={transferCustomerAssets}
                        onChange={(event) => setTransferCustomerAssets(event.target.checked)}
                    />
                </Card>
            </Control>
        </>
    );
};
