import { useState } from 'react';
import { Select, TextField } from '@cimpress/react-components';
import type { SelectorOption } from './types';

interface CustomSelectorProps<T extends string | undefined> {
    selectorLabel?: string;
    textfieldLabel?: string;
    data: SelectorOption[];
    value: T;
    setValue: (value: T) => void;
    validator: (value: string) => boolean;
}

const CUSTOM_LABEL = 'Custom';

export function CustomSelector<T extends string | undefined>({
    selectorLabel,
    textfieldLabel,
    data,
    value,
    setValue,
    validator = () => true,
}: CustomSelectorProps<T>) {
    const customOption: SelectorOption = { label: CUSTOM_LABEL, value: '' };
    const dataWithCustomOption: SelectorOption[] = [customOption, ...data];

    const indexOfCurrentValue = dataWithCustomOption.findIndex((d) => d.value === value);
    const initialState = dataWithCustomOption[indexOfCurrentValue] || customOption;

    const [selection, setSelection] = useState(initialState);
    const [showInputField, setShowInputField] = useState(indexOfCurrentValue <= 0);

    const onChangeSelect = (selected: SelectorOption | null | undefined) => {
        if (!selected) {
            return;
        }

        setSelection(selected);

        if (selected.label !== customOption.label) {
            setValue(selected.value as T);
        } else {
            setValue(value);
        }

        setShowInputField(selected.label === CUSTOM_LABEL);
    };

    return (
        <>
            <Select onChange={onChangeSelect} value={selection} options={dataWithCustomOption} label={selectorLabel} />

            {showInputField && (
                <TextField
                    label={textfieldLabel}
                    value={value}
                    onChange={(e) => setValue(e.target.value as T)}
                    status={!value || validator(value) ? undefined : 'error'}
                />
            )}
        </>
    );
}
