import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AGS_API_KEY, NONE_SELECTOR_OPTION } from '../../constants';
import contentAlignmentOptions from '../../data/contentAlignmentOptions.json';
import type { SelectorOption } from '../common';

const DEFAULT_SURFACE_URL =
    'https://surface.products.cimpress.io/v3/calculatedsurfacesets/CIM-C7J4ZTK1?variables=%7B%22Decoration%20Technology%22%3A%22Full%20Color%22%2C%22Decoration%20Area%22%3A%22Full%20Front%22%2C%22Backside%22%3A%22Blank%22%7D';

const DEFAULT_CONTENT_ALIGNMENT = contentAlignmentOptions[0];

interface UseInspirationGenerateParams {
    themeQuery: string | null;
    primaryText: string | null;
    secondaryText: string | null;
    surfaceSpecificationUrl: string | null;
    surfaceSpecification: any;
    substrateColor: string | null;
    colorPaletteSelectedOption: SelectorOption;
    textOnly: boolean;
    projection: SelectorOption | null;
    iconCollection: string | null;
    contentAlignment: SelectorOption;
    apiKey: string;
    setThemeQuery: (themeQuery: string) => void;
    setPrimaryText: (primaryText: string) => void;
    setSecondaryText: (secondaryText: string) => void;
    setSurfaceSpecificationUrl: (url: string) => void;
    setSurfaceSpecification: (surfaceSpec: any) => void;
    setSubstrateColor: (substrateColor: string) => void;
    setColorPaletteSelectedOption: (colorPaletteOption: SelectorOption) => void;
    setTextOnly: (textOnly: boolean) => void;
    setProjection: (projection: SelectorOption) => void;
    setContentAlignment: (contentAlignmentOption: SelectorOption) => void;
}

export const useInspirationGenerateParams = (): UseInspirationGenerateParams => {
    const [searchParams, setSearchParams] = useSearchParams();

    const themeQueryParam = searchParams.get('themeQuery');
    const primaryTextParam = searchParams.get('primaryText');
    const secondaryTextParam = searchParams.get('secondaryText');
    const surfaceSpecificationParam = searchParams.get('surfaceSpecification');
    const surfaceSpecificationUrlParam = searchParams.get('surfaceSpecificationUrl');
    const projectionQueryParam = searchParams.get('projection');
    const substrateColorParam = searchParams.get('substrateColor');
    const colorPaletteParam = searchParams.get('colorPaletteSelectedOption');
    const textOnlyParam = searchParams.has('textOnly') ? searchParams.get('textOnly') === 'true' : false;
    const apiKey = searchParams.get('apiKey') ?? AGS_API_KEY;
    const iconCollection = searchParams.get('iconCollection');
    const contentAlignmentParam = searchParams.get('contentAlignment');

    const [themeQuery, setThemeQuery] = useState(themeQueryParam);
    const [primaryText, setPrimaryText] = useState(primaryTextParam);
    const [secondaryText, setSecondaryText] = useState(secondaryTextParam);
    const [surfaceSpecificationUrl, setSurfaceSpecificationUrl] = useState(
        surfaceSpecificationUrlParam ?? DEFAULT_SURFACE_URL,
    );
    const [surfaceSpecification, setSurfaceSpecification] = useState(
        surfaceSpecificationParam ? JSON.parse(surfaceSpecificationParam) : undefined,
    );
    const [substrateColor, setSubstrateColor] = useState(substrateColorParam);
    const [colorPaletteSelectedOption, setColorPaletteSelectedOption] = useState(
        colorPaletteParam ? { label: 'Custom', value: colorPaletteParam } : NONE_SELECTOR_OPTION,
    );
    const [textOnly, setTextOnly] = useState(textOnlyParam);
    const [projection, setProjection] = useState<SelectorOption>(
        projectionQueryParam ? { label: projectionQueryParam, value: projectionQueryParam } : NONE_SELECTOR_OPTION,
    );
    const [contentAlignment, setContentAlignment] = useState<SelectorOption>(
        contentAlignmentParam
            ? { label: contentAlignmentParam, value: contentAlignmentParam }
            : DEFAULT_CONTENT_ALIGNMENT,
    );

    useEffect(() => {
        const searchParamsObj: Record<string, string | string[]> = {};

        if (themeQuery) {
            searchParamsObj['themeQuery'] = themeQuery;
        }

        if (primaryText) {
            searchParamsObj['primaryText'] = primaryText;
        }

        if (secondaryText) {
            searchParamsObj['secondaryText'] = secondaryText;
        }

        if (projection && projection.value) {
            searchParamsObj['projection'] = projection.value;
        }

        if (surfaceSpecificationUrl) {
            searchParamsObj['surfaceSpecificationUrl'] = surfaceSpecificationUrl;
        }

        if (surfaceSpecification) {
            searchParamsObj['surfaceSpecification'] = JSON.stringify(surfaceSpecification);
        }

        if (substrateColor) {
            searchParamsObj['substrateColor'] = substrateColor;
        }

        if (textOnly) {
            searchParamsObj['textOnly'] = `${textOnly}`;
        }

        if (colorPaletteSelectedOption.value) {
            searchParamsObj['colorPaletteSelectedOption'] = colorPaletteSelectedOption.value;
        }

        if (iconCollection) {
            searchParamsObj['iconCollection'] = iconCollection;
        }

        if (contentAlignment && contentAlignment.value) {
            searchParamsObj['contentAlignment'] = contentAlignment.value;
        }

        if (apiKey) {
            searchParamsObj['apiKey'] = apiKey;
        }

        setSearchParams(searchParamsObj);
    }, [
        themeQuery,
        primaryText,
        secondaryText,
        colorPaletteSelectedOption,
        surfaceSpecificationUrl,
        surfaceSpecification,
        substrateColor,
        textOnly,
        setSearchParams,
        projection,
        apiKey,
        iconCollection,
        contentAlignment,
    ]);

    return {
        themeQuery,
        primaryText,
        secondaryText,
        surfaceSpecificationUrl,
        surfaceSpecification,
        substrateColor,
        colorPaletteSelectedOption,
        textOnly,
        projection,
        contentAlignment,
        apiKey,
        iconCollection,
        setThemeQuery,
        setPrimaryText,
        setSecondaryText,
        setSurfaceSpecificationUrl,
        setSurfaceSpecification,
        setSubstrateColor,
        setColorPaletteSelectedOption,
        setTextOnly,
        setProjection,
        setContentAlignment,
    };
};
