import { ContentCard } from './ContentCard';
import { ContentSection } from './ContentSection';

export { Control } from './Control';
export { Page } from './Page';

export const Content = {
    Card: ContentCard,
    Section: ContentSection,
};
