import { type DependencyList, type EffectCallback, useEffect, useRef } from 'react';

/**
 * Executes the provided function when values in the dependency list change.
 * Effectively behaves like `useEffect`, except it skips the first render.
 */
export function useUpdateEffect(fn: EffectCallback, dependencies?: DependencyList) {
    const isMountingRef = useRef(false);

    useEffect(() => {
        // Strict Mode causes React to run useEffect twice in development mode.
        // If we just passed `true` as the default value to `useRef`,
        // the benefits of this hook would be negated.
        // Hence why we need to set it this way.
        // https://react.dev/reference/react/StrictMode
        isMountingRef.current = true;
    }, []);

    useEffect(() => {
        if (!isMountingRef.current) {
            return fn();
        } else {
            isMountingRef.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
}
