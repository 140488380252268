import { useEffect, useState } from 'react';
import { getTransferStyleUrl } from '../api';
import { CardBuilder, Page, PreviewRow, PreviewSection } from '../Components';
import { TransferStyleControls } from '../Components/TransferStyle';
import { getUrl } from '../tools';
import type { Card } from '../Components/common';
import { useTransferStyleParams } from '../Components/TransferStyle/useTransferStyleParams';

const intro = `This is the page to test our transfer style endpoint.\nFill in the source and target to see the transfer result`;

export const TransferStyle = () => {
    const transferStyleParams = useTransferStyleParams();
    const [previewRows, setPreviewRows] = useState<Card[][]>([]);

    useEffect(() => {
        const rows: Card[][] = [];
        const originRow = PreviewRow();

        if (transferStyleParams.sourceUrl) {
            const sourceCard = CardBuilder.ofType('documentUrl')
                .withTitle('Source Document')
                .withData({ documentUrl: getUrl(transferStyleParams.sourceUrl) ?? '' })
                .build();
            originRow.push(sourceCard);
        }

        if (transferStyleParams.targetUrl) {
            const targetCard = CardBuilder.ofType('documentUrl')
                .withTitle('Target Document')
                .withData({ documentUrl: getUrl(transferStyleParams.targetUrl) ?? '' })
                .build();
            originRow.push(targetCard);
        }

        rows.push(originRow);

        if (transferStyleParams.sourceUrl && transferStyleParams.targetUrl) {
            const resultCard = CardBuilder.ofType('documentUrl')
                .withTitle('Result Document')
                .withData({
                    documentUrl:
                        getTransferStyleUrl(transferStyleParams.sourceUrl, transferStyleParams.targetUrl) ?? '',
                    backgroundColor: 'FFFFFF',
                })
                .build();
            const resultRow = PreviewRow(resultCard);
            rows.push(resultRow);
        }

        setPreviewRows(rows);
    }, [transferStyleParams.sourceUrl, transferStyleParams.targetUrl]);

    return (
        <Page>
            <Page.Title>Transfer Style</Page.Title>

            <Page.Controls>
                <TransferStyleControls
                    sourceUrl={transferStyleParams.sourceUrl}
                    targetUrl={transferStyleParams.targetUrl}
                    setSourceUrl={transferStyleParams.setSourceUrl}
                    setTargetUrl={transferStyleParams.setTargetUrl}
                />
            </Page.Controls>

            <Page.Content>
                <PreviewSection
                    introduction={intro}
                    showIntro={!transferStyleParams.sourceUrl && !transferStyleParams.targetUrl}
                    rows={previewRows}
                />
            </Page.Content>
        </Page>
    );
};
