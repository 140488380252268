import { isUrl } from '../../tools';
import { CustomSelector } from '../common';
import { useSampleDocuments } from '../common/useSampleDocuments';
import { Control } from '../layout';

interface ResizeSourceControlProps {
    document: string;
    setDocument: (document: string) => void;
}

export const ResizeSourceControl = ({ document, setDocument }: ResizeSourceControlProps) => {
    const documents = useSampleDocuments('resize_source_documents.json');

    return (
        <Control>
            <Control.Title>Source Document or Template</Control.Title>
            <CustomSelector
                selectorLabel={'Document (or template)'}
                textfieldLabel={'Document URL, Template URL '}
                data={documents}
                value={document}
                setValue={setDocument}
                validator={isUrl}
            />
        </Control>
    );
};
