import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SOURCE_URL_PARAM, TARGET_DECORATION_TECHNOLOGY_PARAM } from '../../constants';
import type { SelectorOption } from '../common';

interface UseAdjustForDecoTechParams {
    document: string;
    targetDecorationTechnology: SelectorOption;
    setDocument: (document: string) => void;
    setTargetDecorationTechnology: (url: SelectorOption) => void;
}

export const options: SelectorOption[] = [
    { value: 'embroideryFlat', label: 'Embroidery Flat' },
    { value: 'embroideryCylinder', label: 'Embroidery Cylinder' },
    { value: 'screenPrint', label: 'Screen Print' },
    { value: 'laserEngraving', label: 'Laser Engraving' },
];

export const useAdjustForDecoTechParams = (): UseAdjustForDecoTechParams => {
    const [searchParams, setSearchParams] = useSearchParams();

    const sourceUrlParam = searchParams.get(SOURCE_URL_PARAM);
    const targetDecorationTechnologyParam = searchParams.get(TARGET_DECORATION_TECHNOLOGY_PARAM);

    const [document, setDocument] = useState(sourceUrlParam || '');
    const [targetDecorationTechnology, setTargetDecorationTechnology] = useState(
        options.find((option) => option.value === targetDecorationTechnologyParam) ?? options[0],
    );

    useEffect(() => {
        const searchParamsObj: Record<string, string> = {};

        if (document) {
            searchParamsObj[SOURCE_URL_PARAM] = document;
        }

        if (targetDecorationTechnology) {
            searchParamsObj[TARGET_DECORATION_TECHNOLOGY_PARAM] = `${targetDecorationTechnology.value}`;
        }

        setSearchParams(searchParamsObj, { replace: true });
    }, [targetDecorationTechnology, document, setSearchParams]);

    return {
        document,
        targetDecorationTechnology,
        setDocument,
        setTargetDecorationTechnology,
    };
};
