import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface UseCompositeParams {
    targetDocumentUrl: string;
    components: any;
    setTargetDocumentUrl: (url: string) => void;
    setComponents: (components: any) => void;
}

export const useCompositeParams = (): UseCompositeParams => {
    const [searchParams, setSearchParams] = useSearchParams();

    const documentUrl = searchParams.get('targetDocumentUrl');
    const componentsParam = searchParams.get('components');

    const [targetDocumentUrl, setTargetDocumentUrl] = useState(documentUrl || '');
    const [components, setComponents] = useState(componentsParam ? JSON.parse(componentsParam) : undefined);

    useEffect(() => {
        const searchParamsObj: Record<string, string> = {};

        if (targetDocumentUrl) {
            searchParamsObj['targetDocumentUrl'] = targetDocumentUrl;
        }

        if (components) {
            searchParamsObj['components'] = JSON.stringify(components);
        }

        setSearchParams(searchParamsObj);
    }, [targetDocumentUrl, components, setSearchParams]);

    return {
        targetDocumentUrl,
        components,
        setTargetDocumentUrl,
        setComponents,
    };
};
