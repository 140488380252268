import { Button, TextField } from '@cimpress/react-components';
import IconColorPaintingPalette from '@cimpress-technology/react-streamline-icons/lib/IconColorPaintingPalette';
import { isRgba, parseColor } from '@mcp-artwork/color-converter';
import { type ChangeEvent, useState } from 'react';
import { type ColorResult, type RGBColor, SketchPicker } from 'react-color';
import { Control } from '../../layout';
import { isValidColorState } from './isValidColorState';

export interface ColorInputProps {
    value: string;
    isValid: boolean;
    label: string;
    onChange: (value: string, isValid: boolean) => void;
}

function rgbToCdif(rgb: RGBColor): string {
    const { r, g, b, a } = rgb;

    // A multiplier of 255.999 gives more accurate results than 255,
    // since there are 256 possible values for a CDIF alpha component
    const cdifAlpha = typeof a === 'number' ? Math.floor(a * 255.999) : undefined;
    const hasAlpha = typeof cdifAlpha === 'number' && cdifAlpha < 255;

    return hasAlpha ? `rgba(${r},${g},${b},${cdifAlpha})` : `rgb(${r},${g},${b})`;
}

function cdifToRgb(cdifColor: string): RGBColor | undefined {
    const parsedColor = parseColor(cdifColor);
    if (!parsedColor || parsedColor.colorSpace !== 'rgb') {
        return undefined;
    }

    if (isRgba(parsedColor.value) && parsedColor.value.a !== 255) {
        const { r, g, b, a } = parsedColor.value;
        return { r, g, b, a: Math.floor((a / 255) * 100) / 100 };
    }

    const { r, g, b } = parsedColor.value;
    return { r, g, b };
}

export const ColorInput = ({ value, isValid, label, onChange }: ColorInputProps) => {
    const [isDirty, setIsDirty] = useState(false);
    const [showPicker, setShowPicker] = useState(false);
    const [pickerColor, setPickerColor] = useState<RGBColor | undefined>();

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newColor = event.target.value;
        const isNewColorValid = isValidColorState(newColor);

        onChange(newColor, isNewColorValid);
    };

    const onPickerToggle = () => {
        setPickerColor(cdifToRgb(value));
        setShowPicker((prev) => !prev);
    };

    const onPickerChange = (color: ColorResult) => {
        setPickerColor(color.rgb);
    };

    const onPickerChangeComplete = (color: ColorResult) => {
        setPickerColor(color.rgb);

        const newColor = rgbToCdif(color.rgb);
        if (value !== newColor) {
            onChange(newColor, true);
        }
    };

    return (
        <Control spacing="none">
            <TextField
                label={label}
                value={value}
                status={isDirty && !isValid ? 'error' : undefined}
                onChange={onInputChange}
                onInput={() => setIsDirty(true)}
                onBlur={() => setIsDirty(true)}
                rightAddon={
                    <Button
                        variant={showPicker ? 'primary' : 'default'}
                        icon={<IconColorPaintingPalette className="icon-addon" />}
                        aria-label="Toggle color picker"
                        onClick={onPickerToggle}
                    />
                }
            />
            {showPicker && (
                <SketchPicker
                    width="unset"
                    color={pickerColor}
                    onChange={onPickerChange}
                    onChangeComplete={onPickerChangeComplete}
                />
            )}
        </Control>
    );
};
