import type { ReactNode } from 'react';
import classNames from 'classnames';

import classes from './Page.module.css';

interface PageProps {
    children: ReactNode | ReactNode[];
    centered?: boolean;
}

const Page = ({ children, centered = false }: PageProps) => (
    <div className={classNames(classes.page, { [classes.centered]: centered })}>{children}</div>
);

const PageTitle = ({ children }: { children: string }) => <div className={classes.title}>{children}</div>;

interface PageLayoutElementProps {
    children: ReactNode | ReactNode[];
    fullWidth?: boolean;
}

const PageControls = ({ children, fullWidth = false }: PageLayoutElementProps) => (
    <div className={classNames(classes.controls, { [classes.fullWidth]: fullWidth })}>{children}</div>
);

const PageContent = ({ children, fullWidth = false }: PageLayoutElementProps) => (
    <div className={classNames(classes.content, { [classes.fullWidth]: fullWidth })}>{children}</div>
);

Page.Title = PageTitle;
Page.Controls = PageControls;
Page.Content = PageContent;

export { Page };
