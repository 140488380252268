import { Robot } from '@cimpress/react-components';

import classes from '../PreviewCard.module.css';

interface ErrorIndicatorProps {
    message: string;
    errorUrl?: string;
}

export const ErrorIndicator = ({ message, errorUrl }: ErrorIndicatorProps) => {
    return (
        <div className={classes.indicator}>
            {errorUrl ? (
                <a href={errorUrl} target="_blank" rel="noreferrer">
                    <Robot size="sm" status="danger" />
                </a>
            ) : (
                <Robot size="sm" status="danger" />
            )}

            <span className={classes.indicatorLabel}>{message}</span>
        </div>
    );
};
