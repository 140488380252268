import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CardBuilder, Control, Page, PreviewRow, PreviewRows, PreviewSection } from '../Components';
import { ComposeControls, ComposePresetSelector, useCompositeParams } from '../Components/Composite';
import { type Card, CustomSelector } from '../Components/common';
import { useSampleDocuments } from '../Components/common/useSampleDocuments';
import { getComposeUrl } from '../api';
import { isUrl } from '../tools';

function generateRefreshKey() {
    return uuidv4();
}

export const CompositePage = () => {
    const documents = useSampleDocuments('composite_source_documents.json');

    const compositeParams = useCompositeParams();
    const [refreshKey, setRefreshKey] = useState<string>(generateRefreshKey);
    const [targetDocumentRow, setTargetDocumentRow] = useState<Card[]>([]);
    const [resultDocumentRow, setResultDocumentRow] = useState<Card[]>([]);

    const setDocumentUrl = (docUrl: string) => {
        if (isUrl(docUrl)) {
            compositeParams.setTargetDocumentUrl(docUrl);
            return;
        }
    };

    useEffect(() => {
        if (compositeParams.targetDocumentUrl) {
            const targetCard = CardBuilder.ofType('documentUrl')
                .withTitle('Target Document')
                .withData({ documentUrl: compositeParams.targetDocumentUrl })
                .build();
            setTargetDocumentRow(PreviewRow(targetCard));
        } else {
            setTargetDocumentRow(PreviewRow());
        }
    }, [compositeParams.targetDocumentUrl]);

    useEffect(() => {
        if (compositeParams.targetDocumentUrl && compositeParams.components) {
            const resultCard = CardBuilder.ofType('documentUrl')
                .withTitle('Result Document')
                .withData({
                    documentUrl: getComposeUrl(compositeParams.targetDocumentUrl, compositeParams.components) ?? '',
                    backgroundColor: 'FFFFFF',
                })
                .build();
            setResultDocumentRow(PreviewRow(resultCard));
        } else {
            setResultDocumentRow(PreviewRow());
        }
    }, [compositeParams.targetDocumentUrl, compositeParams.components]);

    return (
        <Page>
            <Page.Title>Compose</Page.Title>

            <Page.Controls>
                <Control>
                    <Control.Title>Layout</Control.Title>
                    <CustomSelector
                        selectorLabel={'Document (or template)'}
                        textfieldLabel={'Document URL, Template URL '}
                        data={documents}
                        value={compositeParams.targetDocumentUrl}
                        setValue={setDocumentUrl}
                        validator={isUrl}
                    />
                </Control>

                <ComposePresetSelector
                    onSelect={(components) => {
                        compositeParams.setComponents(components);
                        setRefreshKey(generateRefreshKey());
                    }}
                />

                <ComposeControls
                    // https://legacy.reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
                    key={refreshKey}
                    initialComponents={compositeParams.components}
                    setComponents={compositeParams.setComponents}
                />
            </Page.Controls>

            <Page.Content>
                <PreviewSection
                    introduction={
                        'This is a page to test the compose endpoint. Fill in the target and assets to see the composed result.'
                    }
                    showIntro={!compositeParams.targetDocumentUrl || !compositeParams.components}
                    rows={PreviewRows(targetDocumentRow, resultDocumentRow)}
                />
            </Page.Content>
        </Page>
    );
};
