import { createRoot } from 'react-dom/client';
import { App } from './App';

import './index.css';

// This will support legacy-style URLs
// Test for "return from login" URL
if (!window.location.search.includes('code=') && !window.location.search.includes('state=')) {
    // If not a login return, check for legacy style URL
    if (window.location.pathname.startsWith('/branch/')) {
        if (!window.location.pathname.endsWith('/index.html')) {
            // For branch deplyments grab the /branch/branch-name part and use the remainder as the hash route
            const parts = window.location.pathname.split('/').filter((x) => !!x);
            const branchPath = `/${parts[0]}/${parts[1]}/index.html`;
            const hashPart = `/${parts.slice(2).join('/')}`;
            const finalPath = `${window.location.protocol}//${window.location.host}${branchPath}#${hashPart}${window.location.search}`;
            window.location.href = finalPath;
        }
    } else if (
        !window.location.href.startsWith(`${window.location.protocol}//${window.location.host}/index.html`) &&
        !window.location.href.startsWith(`${window.location.protocol}//${window.location.host}/#/`)
    ) {
        // Legacy link to root - the entire path and query go into hash
        const finalPath = `${window.location.protocol}//${window.location.host}/#${window.location.pathname}${window.location.search}`;
        window.location.href = finalPath;
    }
}
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
