import { type DependencyList, useEffect } from 'react';

type CancelableEffectCallback = (signal: AbortSignal) => void | (() => void);

/**
 * `useEffect` with built-in cancelation capabilities using `AbortSignal`.
 */
export function useCancelableEffect(fn: CancelableEffectCallback, dependencies?: DependencyList) {
    useEffect(() => {
        const abortController = new AbortController();

        const cleanupFn = fn(abortController.signal);

        return () => {
            abortController.abort();

            if (typeof cleanupFn === 'function') {
                cleanupFn();
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
}
