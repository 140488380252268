import { type DependencyList, type EffectCallback, useRef } from 'react';
import { useUpdateEffect } from './useUpdateEffect';

/**
 * Executes the provided function when values in the dependency list change,
 * but debounces execution by the provided delay.
 */
export function useDebouncedEffect(fn: EffectCallback, dependencies: DependencyList | undefined, delayInMs: number) {
    const cleanupFn = useRef<(() => void) | undefined>();

    useUpdateEffect(() => {
        const timeoutId = setTimeout(() => {
            const result = fn();

            if (typeof result === 'function') {
                cleanupFn.current = result;
            }
        }, delayInMs);

        return () => {
            clearTimeout(timeoutId);
            cleanupFn.current?.();
            cleanupFn.current = undefined;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
}
