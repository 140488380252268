import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { NONE_SELECTOR_OPTION } from '../../constants';
import type { SelectorOption } from '../common';
import type { Physicality } from './types';

interface UseResizeParams {
    document: string;
    physicalities: Physicality[];
    surfaceSpecificationUrl: string;
    surfaceSpecification: any;
    customPhysicality?: Physicality;
    debug: boolean;
    projection: SelectorOption;
    minimumFontSize: string;
    setDocument: (document: string) => void;
    setPhysicalities: (physicalities: Physicality[]) => void;
    setSurfaceSpecificationUrl: (url: string) => void;
    setSurfaceSpecification: (surfaceSpec: any) => void;
    setCustomPhysicality: (physicality: Physicality | undefined) => void;
    setDebug: (debug: boolean) => void;
    setProjection: (projection: SelectorOption) => void;
    setMinimumFontSize: (minimumFontSize: string) => void;
}

export const useResizeParams = (): UseResizeParams => {
    const [searchParams, setSearchParams] = useSearchParams();
    const physicalitiesParam = searchParams.get('physicalities');
    const customPhysicalitiesParam = searchParams.get('customphysicalities');

    const documentUrl = searchParams.get('documentUrl');
    const physicalitiesJson = physicalitiesParam ? JSON.parse(physicalitiesParam) : [];
    const customPhysicalitiesJson = customPhysicalitiesParam ? JSON.parse(customPhysicalitiesParam) : '';
    const surfaceSpecificationParam = searchParams.get('surfaceSpecification');
    const surfaceSpecificationUrlParam = searchParams.get('surfaceSpecificationUrl');
    const debugMode = searchParams.get('debug');
    const projectionQueryParam = searchParams.get('projection');
    const minimumFontSizeParam = searchParams.get('minimumFontSize');

    const [document, setDocument] = useState(documentUrl || '');
    const [physicalities, setPhysicalities] = useState<Physicality[]>(physicalitiesJson || []);
    const [customPhysicality, setCustomPhysicality] = useState<Physicality | undefined>(customPhysicalitiesJson);
    const [surfaceSpecificationUrl, setSurfaceSpecificationUrl] = useState(surfaceSpecificationUrlParam || '');
    const [surfaceSpecification, setSurfaceSpecification] = useState(
        surfaceSpecificationParam ? JSON.parse(surfaceSpecificationParam) : undefined,
    );
    const [debug, setDebug] = useState(debugMode === 'true');
    const [projection, setProjection] = useState<SelectorOption>(
        projectionQueryParam ? { label: projectionQueryParam, value: projectionQueryParam } : NONE_SELECTOR_OPTION,
    );
    const [minimumFontSize, setMinimumFontSize] = useState(minimumFontSizeParam || '');

    useEffect(() => {
        const searchParamsObj: Record<string, string> = {};

        if (document) {
            searchParamsObj['documentUrl'] = document;
        }

        if (physicalities.length > 0) {
            searchParamsObj['physicalities'] = JSON.stringify(physicalities);
        }

        if (surfaceSpecificationUrl) {
            searchParamsObj['surfaceSpecificationUrl'] = surfaceSpecificationUrl;
        }

        if (surfaceSpecification) {
            searchParamsObj['surfaceSpecification'] = JSON.stringify(surfaceSpecification);
        }

        if (projection && projection.value) {
            searchParamsObj['projection'] = projection.value;
        }

        if (customPhysicality) {
            searchParamsObj['customphysicalities'] = JSON.stringify(customPhysicality);
        }

        if (minimumFontSize) {
            searchParamsObj['minimumFontSize'] = minimumFontSize;
        }

        setSearchParams(searchParamsObj);
    }, [
        document,
        physicalities,
        surfaceSpecificationUrl,
        surfaceSpecification,
        projection,
        customPhysicality,
        minimumFontSize,
        setSearchParams,
    ]);

    return {
        document,
        physicalities,
        surfaceSpecificationUrl,
        surfaceSpecification,
        customPhysicality,
        debug,
        projection,
        minimumFontSize,
        setDocument,
        setPhysicalities,
        setSurfaceSpecificationUrl,
        setSurfaceSpecification,
        setCustomPhysicality,
        setDebug,
        setProjection,
        setMinimumFontSize,
    };
};
