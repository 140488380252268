export function tryParseJson(text: string): object | undefined {
    try {
        const result = JSON.parse(text);

        if (result && typeof result === 'object') {
            return result;
        }
    } catch {
        // in case parsing fails, we assume provided string is not a valid JSON
    }

    return undefined;
}
