import { Button } from '@cimpress/react-components';
import IconAdd from '@cimpress-technology/react-streamline-icons/lib/IconAdd';
import IconRemove from '@cimpress-technology/react-streamline-icons/lib/IconRemove';
import { Control } from '../layout';

import classes from './ControlTitleToggle.module.css';

interface ControlTitleToggleProps {
    title: string;
    isOn: boolean;
    onToggle: (isOn: boolean) => void;
}

export const ControlTitleToggle = ({ title, isOn, onToggle }: ControlTitleToggleProps) => {
    return (
        <div className={classes.wrapper}>
            <Control.Title>{title}</Control.Title>

            <Button
                variant="primary"
                color={isOn ? 'danger' : 'primary'}
                icon={isOn ? <IconRemove /> : <IconAdd />}
                aria-label={isOn ? `Remove ${title}` : `Add ${title}`}
                onClick={() => onToggle(!isOn)}
            />
        </div>
    );
};
