import { Button } from '@cimpress/react-components';

import classes from '../PreviewCard.module.css';

interface PanelSwitcherProps {
    panelCount: number;
    panelIndex: number;
    onChange: (panelIndex: number) => void;
}

export const PanelSwitcher = ({ panelCount, panelIndex, onChange }: PanelSwitcherProps) => {
    const panelNumber = panelIndex + 1;
    const hasPrevious = panelNumber > 1;
    const hasNext = panelNumber < panelCount;

    if (panelCount < 1) {
        return null;
    }

    return (
        <div className={classes.panelSwitcher}>
            <Button variant="link" size="sm" disabled={!hasPrevious} onClick={() => onChange(panelIndex - 1)}>
                &lt;
            </Button>
            <span>
                {panelNumber} / {panelCount}
            </span>
            <Button variant="link" size="sm" disabled={!hasNext} onClick={() => onChange(panelIndex + 1)}>
                &gt;
            </Button>
        </div>
    );
};
