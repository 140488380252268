import { useState } from 'react';
import classNames from 'classnames';
import { Button, TextField } from '@cimpress/react-components';
import IconTextBold from '@cimpress-technology/react-streamline-icons/lib/IconTextBold';
import IconTextItalic from '@cimpress-technology/react-streamline-icons/lib/IconTextItalic';
import { isStringEmptyOrWhitespace, useUpdateEffect } from '../../../tools';
import type { FontAsset } from './types';

import classes from './FontInput.module.css';

export interface FontInputProps {
    initialValue?: FontAsset;
    label: string;
    required?: boolean;
    onChange: (value?: FontAsset) => void;
}

interface FontState {
    family: string;
    isBold: boolean;
    isItalic: boolean;
}

function assetToState(fontAsset?: FontAsset): FontState {
    const style = fontAsset?.style?.toLowerCase() ?? 'normal';

    return {
        family: fontAsset?.family ?? '',
        isBold: style.includes('bold'),
        isItalic: style.includes('italic'),
    };
}

function stateToAsset({ family, isBold, isItalic }: FontState): FontAsset {
    const styles: string[] = [];

    if (isBold) {
        styles.push('bold');
    }

    if (isItalic) {
        styles.push('italic');
    }

    return {
        family,
        style: styles.length ? styles.join(',') : 'normal',
    };
}

export const FontInput = ({ initialValue, label, required = false, onChange }: FontInputProps) => {
    const [font, setFont] = useState(() => assetToState(initialValue));
    const [isDirty, setIsDirty] = useState(false);

    useUpdateEffect(() => {
        if (isStringEmptyOrWhitespace(font.family)) {
            onChange(undefined);
        } else {
            onChange(stateToAsset(font));
        }
    }, [font, onChange]);

    const { family, isBold, isItalic } = font;

    return (
        <TextField
            className={classes.inputWrapper}
            label={label}
            required={required}
            value={family}
            status={required && isDirty && isStringEmptyOrWhitespace(family) ? 'error' : undefined}
            onChange={(e) => setFont((prev) => ({ ...prev, family: e.target.value }))}
            onInput={() => setIsDirty(true)}
            onBlur={() => setIsDirty(true)}
            rightAddon={
                <>
                    <Button
                        variant={isBold ? 'primary' : 'default'}
                        icon={
                            <IconTextBold className={classNames(classes.fontStyleIcon, 'icon-addon')} weight="fill" />
                        }
                        aria-label="Toggle bold style"
                        onClick={() => setFont((prev) => ({ ...prev, isBold: !prev.isBold }))}
                    />
                    <Button
                        variant={isItalic ? 'primary' : 'default'}
                        icon={
                            <IconTextItalic className={classNames(classes.fontStyleIcon, 'icon-addon')} weight="fill" />
                        }
                        aria-label="Toggle italic style"
                        onClick={() => setFont((prev) => ({ ...prev, isItalic: !prev.isItalic }))}
                    />
                </>
            }
        />
    );
};
