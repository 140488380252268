import { useState } from 'react';
import { useUpdateEffect } from '../../../tools';
import { Control } from '../../layout';
import { FontInput } from './FontInput';
import type { FontAsset, FontSchema } from './types';

export interface FontSchemaEditorProps {
    initialValue?: FontSchema;
    onChange: (value?: FontSchema) => void;
}

export const FontSchemaEditor = ({ initialValue, onChange }: FontSchemaEditorProps) => {
    const [primary, setPrimary] = useState<FontAsset | undefined>(initialValue?.primary);
    const [secondary, setSecondary] = useState<FontAsset | undefined>(initialValue?.secondary);
    const [tertiary, setTertiary] = useState<FontAsset | undefined>(initialValue?.tertiary);

    useUpdateEffect(() => {
        if (!primary) {
            onChange(undefined);
            return;
        }

        const newSchema: FontSchema = {
            primary,
        };

        if (secondary) {
            newSchema.secondary = secondary;
        }

        if (tertiary) {
            newSchema.tertiary = tertiary;
        }

        onChange(newSchema);
    }, [primary, secondary, tertiary, onChange]);

    return (
        <Control spacing="compact">
            <FontInput label="Primary" required initialValue={primary} onChange={setPrimary} />
            <FontInput label="Secondary" initialValue={secondary} onChange={setSecondary} />
            <FontInput label="Tertiary" initialValue={tertiary} onChange={setTertiary} />
        </Control>
    );
};
