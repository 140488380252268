import axios from 'axios';
import { AUTHORIZATION_HEADERS } from './constants';

export const uploadImageAndGetImageUrl = async (img: Blob, signal?: AbortSignal): Promise<string> => {
    const uploadApi = 'https://uploads.documents.cimpress.io/v1/uploads';
    const formData = new FormData();
    formData.append('file', img);

    try {
        const result = await axios.post<[{ uploadId: string }]>(uploadApi, formData, {
            headers: { ...AUTHORIZATION_HEADERS, 'Content-Type': 'multipart/form-data' },
            signal,
        });
        const imageId = result.data[0].uploadId;

        return `https://uploads.documents.cimpress.io/v1/uploads/${imageId}`;
    } catch (error) {
        if (signal?.aborted) {
            throw error;
        }

        console.error(
            `An error occurred while uploading to "https://uploads.documents.cimpress.io/v1/uploads": ${error}`,
        );
        throw error;
    }
};
