import { useState } from 'react';
import { useDebouncedEffect } from '../../tools';
import { Control } from '../layout';
import { type ColorPalette, ColorPaletteEditor } from './colors';
import { type FontSchema, FontSchemaEditor } from './fonts';
import { type ImageAsset, ImageAssetEditor } from './images';
import { type TextAsset, TextListEditor } from './texts';
import { ControlTitleToggle } from './ControlTitleToggle';

export interface ComposeControlsProps {
    initialComponents?: any;
    setComponents: (components: any) => void;
}

export const ComposeControls = ({ initialComponents, setComponents }: ComposeControlsProps) => {
    const initialTexts = initialComponents?.texts;
    const initialBackgroundImage = initialComponents?.backgroundImages?.[0];
    const initialLogo = initialComponents?.logos?.[0];
    const initialFontSchema = initialComponents?.fontSchemas?.[0];
    const initialColorPalette = initialComponents?.colorPalettes?.[0];

    const [texts, setTexts] = useState<TextAsset[] | undefined>(initialTexts);

    const [backgroundImage, setBackgroundImage] = useState<ImageAsset | undefined>(initialBackgroundImage);
    const [isBackgroundImageRequired, setIsBackgroundImageRequired] = useState(!!initialBackgroundImage);

    const [logo, setLogo] = useState<ImageAsset | undefined>(initialLogo);
    const [isLogoRequired, setIsLogoRequired] = useState(!!initialLogo);

    const [fontSchema, setFontSchema] = useState<FontSchema | undefined>(initialFontSchema);
    const [isFontSchemaRequired, setIsFontSchemaRequired] = useState(!!initialFontSchema);

    const [colorPalette, setColorPalette] = useState<ColorPalette | undefined>(initialColorPalette);
    const [isColorPaletteRequired, setIsColorPaletteRequired] = useState(!!initialColorPalette);

    useDebouncedEffect(
        () => {
            if (!(texts && texts.length) && !backgroundImage && !logo && !fontSchema && !colorPalette) {
                // No content
                setComponents(undefined);
                return;
            }

            if (
                texts &&
                (!isBackgroundImageRequired || backgroundImage) &&
                (!isLogoRequired || logo) &&
                (!isFontSchemaRequired || fontSchema) &&
                (!isColorPaletteRequired || colorPalette)
            ) {
                setComponents({
                    texts: texts.length ? texts : undefined,
                    backgroundImages: backgroundImage ? [backgroundImage] : undefined,
                    logos: logo ? [logo] : undefined,
                    fontSchemas: fontSchema ? [fontSchema] : undefined,
                    colorPalettes: colorPalette ? [colorPalette] : undefined,
                });
            } else {
                setComponents(undefined);
            }
        },
        [
            texts,
            isBackgroundImageRequired,
            backgroundImage,
            isLogoRequired,
            logo,
            isFontSchemaRequired,
            fontSchema,
            isColorPaletteRequired,
            colorPalette,
            setComponents,
        ],
        500,
    );

    return (
        <>
            <Control>
                <Control.Title>Texts</Control.Title>
                <TextListEditor initialValue={texts} onChange={setTexts} />
            </Control>

            <Control>
                <ControlTitleToggle
                    title="Background Image"
                    isOn={isBackgroundImageRequired}
                    onToggle={(isOn) => {
                        if (isOn) {
                            setIsBackgroundImageRequired(true);
                        } else {
                            setIsBackgroundImageRequired(false);
                            setBackgroundImage(undefined);
                        }
                    }}
                />

                {isBackgroundImageRequired && (
                    <ImageAssetEditor initialValue={backgroundImage} onChange={setBackgroundImage} />
                )}
            </Control>

            <Control>
                <ControlTitleToggle
                    title="Logo"
                    isOn={isLogoRequired}
                    onToggle={(isOn) => {
                        if (isOn) {
                            setIsLogoRequired(true);
                        } else {
                            setIsLogoRequired(false);
                            setLogo(undefined);
                        }
                    }}
                />

                {isLogoRequired && <ImageAssetEditor initialValue={logo} onChange={setLogo} />}
            </Control>

            <Control>
                <ControlTitleToggle
                    title="Font Schema"
                    isOn={isFontSchemaRequired}
                    onToggle={(isOn) => {
                        if (isOn) {
                            setIsFontSchemaRequired(true);
                        } else {
                            setIsFontSchemaRequired(false);
                            setFontSchema(undefined);
                        }
                    }}
                />

                {isFontSchemaRequired && <FontSchemaEditor initialValue={fontSchema} onChange={setFontSchema} />}
            </Control>

            <Control>
                <ControlTitleToggle
                    title="Color Palette"
                    isOn={isColorPaletteRequired}
                    onToggle={(isOn) => {
                        if (isOn) {
                            setIsColorPaletteRequired(true);
                        } else {
                            setIsColorPaletteRequired(false);
                            setColorPalette(undefined);
                        }
                    }}
                />

                {isColorPaletteRequired && (
                    <ColorPaletteEditor initialValue={colorPalette} onChange={setColorPalette} />
                )}
            </Control>
        </>
    );
};
