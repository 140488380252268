import { v4 as uuidv4 } from 'uuid';
import { isUrl } from './validators';

export const getUrl = (str: string): string | null => {
    if (!str) {
        return null;
    }

    if (isUrl(str)) {
        return str;
    }

    return null;
};

export const REQUESTER = `playgroundUI`;

export const getNonceQueryParam = (): string => `nonce=${uuidv4()}`;
