import { useState } from 'react';
import { CustomSelector, DragDropImageUploader, type SelectorOption } from '../../common';
import { isUrl } from '../../../tools';

const UPLOAD_IMAGE_VALUE = 'UPLOAD_IMAGE_VALUE';

interface ImageUrlSelectorProps {
    url?: string;
    setIsImageLoading: (isLoading: boolean) => void;
    onChange: (url: string | undefined) => void;
}

const SELECTOR_DATA: SelectorOption[] = [
    {
        label: 'Upload Image',
        value: UPLOAD_IMAGE_VALUE,
    },
];

export const ImageUrlSelector = ({ url, setIsImageLoading, onChange }: ImageUrlSelectorProps) => {
    const [showImageUploader, setShowImageUploader] = useState(false);

    const onSelectChange = (selectedValue?: string) => {
        if (selectedValue === UPLOAD_IMAGE_VALUE) {
            onChange(undefined);
            setShowImageUploader(true);
        } else {
            onChange(selectedValue);
            setShowImageUploader(false);
        }
    };

    return (
        <>
            <CustomSelector
                selectorLabel="URL"
                textfieldLabel="URL"
                data={SELECTOR_DATA}
                value={url}
                setValue={onSelectChange}
                validator={isUrl}
            />

            {showImageUploader && (
                <DragDropImageUploader
                    onUploadingStateChange={setIsImageLoading}
                    onChange={onChange}
                    onClear={() => onChange(undefined)}
                />
            )}
        </>
    );
};
