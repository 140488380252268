import { StrictMode, useEffect, useState } from 'react';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import Header from '@cimpress-technology/react-platform-header';
import { FeatureFlags, GlobalStyles, type FeatureFlagsConfig } from '@cimpress/react-components';
import { auth, initializeAuth, type AuthProfile } from './Auth';
import { ErrorBoundary } from './Components';
import { hiddenRoutes, ProblemSpaceNavigation, routes } from './navigation';

const COMPONENT_LIBRARY_FEATURE_FLAGS: FeatureFlagsConfig = {
    v17_noOuterSpacing: true,
};

export const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [authToken, setAuthToken] = useState('');
    const [profile, setProfile] = useState<AuthProfile | undefined>(undefined);

    useEffect(() => {
        initializeAuth();
        auth.on('tokenExpired', () => {
            setIsLoggedIn(false);
            setAuthToken('');
            setProfile(undefined);
        });
        auth.on('authenticated', () => {
            setIsLoggedIn(true);
            setAuthToken(auth.getAccessToken() ?? '');
            setProfile(auth.getProfile());
        });
    }, []);

    return (
        <StrictMode>
            <FeatureFlags flags={COMPONENT_LIBRARY_FEATURE_FLAGS}>
                <GlobalStyles />
                <HashRouter>
                    <Header
                        // hide collapsible menu on mobile since we don't use it
                        responsive={false}
                        appTitle={<div className="app-title">Artwork Generation Playground</div>}
                        profile={profile}
                        onLogInClicked={() => auth.login({ nextUri: '#/', forceLogin: false })}
                        onLogOutClicked={() => auth.logout('#/')}
                        isLoggedIn={isLoggedIn}
                        accessToken={authToken}
                        notifications={null}
                        showLeftNav
                    />
                    <ProblemSpaceNavigation />
                    <ErrorBoundary>
                        <Routes>
                            <Route path="" element={<Navigate to={routes[0].href} replace />} />
                            {routes.map(({ href, element }) => (
                                <Route key={href} path={href} element={element} />
                            ))}
                            {hiddenRoutes.map(({ href, element }) => (
                                <Route key={href} path={href} element={element} />
                            ))}
                        </Routes>
                    </ErrorBoundary>
                </HashRouter>
            </FeatureFlags>
        </StrictMode>
    );
};
