import { isUrl } from '../../tools';
import { CustomSelector } from '../common';
import { useSampleDocuments } from '../common/useSampleDocuments';
import { Control } from '../layout';

interface TransferStyleControlsProps {
    sourceUrl: string;
    setSourceUrl: (sourceUrl: string) => void;
    targetUrl: string;
    setTargetUrl: (targetUrl: string) => void;
}

export const TransferStyleControls = ({
    sourceUrl,
    setSourceUrl,
    targetUrl,
    setTargetUrl,
}: TransferStyleControlsProps) => {
    const sources = useSampleDocuments('transferStyle_source_documents.json');
    const targets = useSampleDocuments('transferStyle_target_documents.json');

    return (
        <>
            <Control>
                <Control.Title>Source Document or Template</Control.Title>
                <CustomSelector
                    selectorLabel="Document (or template)"
                    textfieldLabel="Document URL, Template URL or Template Token"
                    data={sources}
                    value={sourceUrl}
                    setValue={setSourceUrl}
                    validator={isUrl}
                />
            </Control>

            <Control>
                <Control.Title>Target Document or Template</Control.Title>
                <CustomSelector
                    selectorLabel="Document (or template)"
                    textfieldLabel="Document URL, Template URL or Template Token"
                    data={targets}
                    value={targetUrl}
                    setValue={setTargetUrl}
                    validator={isUrl}
                />
            </Control>
        </>
    );
};
