import { Button } from '@cimpress/react-components';
import IconClose from '@cimpress-technology/react-streamline-icons/lib/IconClose';

import classes from './CloseButton.module.css';

interface CloseButtonProps {
    onClick: () => void;
}

export const CloseButton = ({ onClick }: CloseButtonProps) => (
    <Button
        className={classes.closeButton}
        variant="anchor"
        icon={<IconClose weight="fill" />}
        aria-label="Close"
        onClick={onClick}
    />
);
