import { TextField } from '@cimpress/react-components';
import { useState } from 'react';
import { useUpdateEffect } from '../../../tools';

interface CropFractionInputProps {
    label: string;
    value?: string | number;
    maxValue: number;
    onChange: (value: number) => void;
}

const CROP_FRACTION_PATTERN = String.raw`0?(\.\d+)?`;
const CROP_FRACTION_REGEX = new RegExp(`^${CROP_FRACTION_PATTERN}$`);

function tryParseCropFraction(value: string): number | undefined {
    return value.length > 0 && CROP_FRACTION_REGEX.test(value) ? +value : undefined;
}

export const CropFractionInput = ({ label, value, maxValue, onChange }: CropFractionInputProps) => {
    const stringValue = `${value ?? 0}`;

    const [inputValue, setInputValue] = useState<string>(stringValue);
    const [parsedValue, setParsedValue] = useState(() => tryParseCropFraction(stringValue));

    const hasValidFraction = parsedValue !== undefined;
    const isValidRange = hasValidFraction ? parsedValue <= maxValue : false;

    useUpdateEffect(() => {
        setInputValue(stringValue);
        setParsedValue(tryParseCropFraction(stringValue));
    }, [stringValue]);

    const onInputChange = (newValue: string) => {
        const newParsedValue = tryParseCropFraction(newValue);

        setInputValue(newValue);
        setParsedValue(newParsedValue);

        if (newParsedValue !== undefined && newParsedValue <= maxValue) {
            onChange(newParsedValue);
        }
    };

    return (
        <TextField
            type="text"
            inputMode="decimal"
            pattern={CROP_FRACTION_PATTERN}
            minLength={1}
            label={label}
            value={inputValue}
            onChange={(event) => onInputChange(event.target.value)}
            status={hasValidFraction && isValidRange ? undefined : 'error'}
            helpText={hasValidFraction && !isValidRange ? `Must be <= ${maxValue}` : ''}
        />
    );
};
