import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface UseAdjustForSurfaceParams {
    document: string;
    surfaceSpecificationUrl: string;
    surfaceSpecification: any;
    targetSubstrateColor: string;
    setDocument: (document: string) => void;
    setSurfaceSpecificationUrl: (url: string) => void;
    setSurfaceSpecification: (surfaceSpec: any) => void;
    setTargetSubstrateColor: (targetSubstrateColor: string) => void;
}

export const useAdjustForSurfaceParams = (): UseAdjustForSurfaceParams => {
    const [searchParams, setSearchParams] = useSearchParams();

    const documentUrl = searchParams.get('documentUrl');
    const surfaceSpecificationParam = searchParams.get('surfaceSpecification');
    const surfaceSpecificationUrlParam = searchParams.get('surfaceSpecificationUrl');
    const substrateColor = searchParams.get('targetSubstrateColor');

    const [document, setDocument] = useState(documentUrl || '');
    const [surfaceSpecificationUrl, setSurfaceSpecificationUrl] = useState(surfaceSpecificationUrlParam || '');
    const [surfaceSpecification, setSurfaceSpecification] = useState(
        surfaceSpecificationParam ? JSON.parse(surfaceSpecificationParam) : undefined,
    );
    const [targetSubstrateColor, setTargetSubstrateColor] = useState(substrateColor || '');

    useEffect(() => {
        const searchParamsObj: Record<string, string> = {};

        if (document) {
            searchParamsObj['documentUrl'] = document;
        }

        if (surfaceSpecificationUrl) {
            searchParamsObj['surfaceSpecificationUrl'] = surfaceSpecificationUrl;
        }

        if (surfaceSpecification) {
            searchParamsObj['surfaceSpecification'] = JSON.stringify(surfaceSpecification);
        }

        if (targetSubstrateColor) {
            searchParamsObj['targetSubstrateColor'] = targetSubstrateColor;
        }

        setSearchParams(searchParamsObj);
    }, [document, surfaceSpecificationUrl, surfaceSpecification, targetSubstrateColor, setSearchParams]);

    return {
        document,
        surfaceSpecificationUrl,
        surfaceSpecification,
        targetSubstrateColor,
        setDocument,
        setSurfaceSpecificationUrl,
        setSurfaceSpecification,
        setTargetSubstrateColor,
    };
};
