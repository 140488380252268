import { type ChangeEvent, type CSSProperties, useState } from 'react';
import { Select, TextArea } from '@cimpress/react-components';
import { toJsonString, tryParseJson, useDebouncedEffect } from '../../tools';

interface SelectorOption {
    label: string;
    value: any;
}

interface ComponentsSelectorProps {
    initialValue: any;
    onChange: (value: any) => void;
    predefinedValues: SelectorOption[];
    rows: number;
    label: string;
}

const JSON_TEXT_AREA_STYLE: CSSProperties = { whiteSpace: 'pre-line' };

export const InputJsonSelector = ({
    initialValue,
    onChange,
    predefinedValues,
    rows,
    label,
}: ComponentsSelectorProps) => {
    const [selection, setSelection] = useState<SelectorOption>();
    const [rawJson, setRawJson] = useState(() => toJsonString(initialValue));
    const [isValidJson, setIsValidJson] = useState(true);

    const onSelectChange = (selected: SelectorOption | null | undefined) => {
        setSelection(selected ?? undefined);
        setRawJson(toJsonString(selected?.value));
    };

    const onJsonChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const newJson = event.target.value;
        setRawJson(newJson);
    };

    useDebouncedEffect(
        () => {
            const parsedJson = tryParseJson(rawJson);

            if (parsedJson) {
                setIsValidJson(true);
                onChange(parsedJson);
            } else if (!rawJson) {
                setIsValidJson(true);
                onChange(undefined);
            } else {
                setIsValidJson(false);
            }
        },
        [rawJson, onChange],
        500,
    );

    return (
        <>
            <Select onChange={onSelectChange} value={selection} options={predefinedValues} label={label} />

            <TextArea
                style={JSON_TEXT_AREA_STYLE}
                value={rawJson}
                rows={rows}
                onChange={onJsonChange}
                status={isValidJson ? undefined : 'error'}
            />
        </>
    );
};
