import { Button } from '@cimpress/react-components';
import { Control } from '../../layout';
import type { CropFractions } from '../types';
import { CropFractionInput } from './CropFractionInput';

import classes from './CropFractionsEditor.module.css';

interface CropFractionsEditorProps {
    cropFractions?: CropFractions;
    onChange: (cropFractions: CropFractions | undefined) => void;
}

const DEFAULT_CROP_FRACTIONS: CropFractions = { top: 0, left: 0, bottom: 0, right: 0 };

export const CropFractionsEditor = ({ cropFractions, onChange }: CropFractionsEditorProps) => {
    const onCropFractionsAdd = () => {
        onChange({ ...DEFAULT_CROP_FRACTIONS });
    };

    if (!cropFractions) {
        return (
            <Control inline>
                <Button onClick={onCropFractionsAdd}>Add Crop</Button>
            </Control>
        );
    }

    const onFractionChange = (value: number, prop: keyof CropFractions) => {
        const newCropFractions: CropFractions = {
            ...cropFractions,
            [prop]: value,
        };

        onChange(newCropFractions);
    };

    const onCropFractionsRemove = () => {
        onChange(undefined);
    };

    return (
        <>
            <Control spacing="compact" className={classes.columns}>
                <CropFractionInput
                    label="Top"
                    value={cropFractions.top}
                    maxValue={1 - cropFractions.bottom}
                    onChange={(value) => onFractionChange(value, 'top')}
                />

                <CropFractionInput
                    label="Bottom"
                    value={cropFractions.bottom}
                    maxValue={1 - cropFractions.top}
                    onChange={(value) => onFractionChange(value, 'bottom')}
                />

                <CropFractionInput
                    label="Left"
                    value={cropFractions.left}
                    maxValue={1 - cropFractions.right}
                    onChange={(value) => onFractionChange(value, 'left')}
                />

                <CropFractionInput
                    label="Right"
                    value={cropFractions.right}
                    maxValue={1 - cropFractions.left}
                    onChange={(value) => onFractionChange(value, 'right')}
                />
            </Control>

            <Control inline>
                <Button onClick={onCropFractionsRemove}>Remove Crop</Button>
            </Control>
        </>
    );
};
