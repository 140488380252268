import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    SOURCE_JSON_PARAM,
    SOURCE_URL_PARAM,
    TARGET_URL_PARAM,
    TRANSFER_CUSTOMER_ASSETS_PARAM,
    USE_SMART_TEXT_TRANSFER_PARAM,
    USE_SOURCE_JSON_PARAM,
} from '../../constants';

interface TransferCustomizationParams {
    sourceUrl: string;
    targetUrl: string;
    transferCustomerAssets: boolean;
    useSmartTextTransfer: boolean;
    sourceJson: any;
    useJsonSource: boolean;
    setSourceUrl: (sourceUrl: string) => void;
    setTargetUrl: (targetUrl: string) => void;
    setSourceJson: (sourceJson: string) => void;
    setTransferCustomerAssets: (transferCustomerAssets: boolean) => void;
    setUseSmartTextTransfer: (useSmartTextTransfer: boolean) => void;
    setUseJsonSource: (useJsonSource: boolean) => void;
}

export const useTransferCustomizationParams = (): TransferCustomizationParams => {
    const [searchParams, setSearchParams] = useSearchParams();

    const sourceUrlParam = searchParams.get(SOURCE_URL_PARAM);
    const targetUrlParam = searchParams.get(TARGET_URL_PARAM);
    const sourceJsonParam = searchParams.get(SOURCE_JSON_PARAM);
    const useSourceJsonParam = searchParams.get(USE_SOURCE_JSON_PARAM);
    const transferCustomerAssetsParam = searchParams.has(TRANSFER_CUSTOMER_ASSETS_PARAM)
        ? searchParams.get(TRANSFER_CUSTOMER_ASSETS_PARAM) === 'true'
        : true;
    const useSmartTextTransferParam = searchParams.has(USE_SMART_TEXT_TRANSFER_PARAM)
        ? searchParams.get(USE_SMART_TEXT_TRANSFER_PARAM) === 'true'
        : true;

    const [sourceUrl, setSourceUrl] = useState(sourceUrlParam ?? '');
    const [targetUrl, setTargetUrl] = useState(targetUrlParam ?? '');
    const [sourceJson, setSourceJson] = useState(sourceJsonParam ? JSON.parse(sourceJsonParam) : '');
    const [transferCustomerAssets, setTransferCustomerAssets] = useState(transferCustomerAssetsParam);
    const [useSmartTextTransfer, setUseSmartTextTransfer] = useState(useSmartTextTransferParam);
    const [useJsonSource, setUseJsonSource] = useState(useSourceJsonParam === 'true');

    useEffect(() => {
        const searchParamsObj: Record<string, string> = {};

        if (sourceUrl) {
            searchParamsObj[SOURCE_URL_PARAM] = sourceUrl;
        }

        if (targetUrl) {
            searchParamsObj[TARGET_URL_PARAM] = targetUrl;
        }

        if (!transferCustomerAssets) {
            searchParamsObj[TRANSFER_CUSTOMER_ASSETS_PARAM] = `${transferCustomerAssets}`;
        }

        if (!useSmartTextTransfer) {
            searchParamsObj[USE_SMART_TEXT_TRANSFER_PARAM] = `${useSmartTextTransfer}`;
        }

        if (sourceJson) {
            searchParamsObj[SOURCE_JSON_PARAM] = JSON.stringify(sourceJson);
        }

        if (useJsonSource) {
            searchParamsObj[USE_SOURCE_JSON_PARAM] = `${useJsonSource}`;
        }

        setSearchParams(searchParamsObj, { replace: true });
    }, [
        sourceUrl,
        targetUrl,
        transferCustomerAssets,
        useSmartTextTransfer,
        sourceJson,
        useJsonSource,
        setSearchParams,
    ]);

    return {
        sourceUrl,
        targetUrl,
        transferCustomerAssets,
        useSmartTextTransfer,
        sourceJson,
        useJsonSource,
        setSourceUrl,
        setTargetUrl,
        setSourceJson,
        setTransferCustomerAssets,
        setUseSmartTextTransfer,
        setUseJsonSource,
    };
};
