const local = {
    backendServiceUrl: 'https://artworkgeneration.cimpress.io',
    playgroundApiUrl: 'https://api.playground.artworkgeneration.cimpress.io',
    samplesS3BaseUrl: 'https://prod-artworkgeneration-playground-ui-settings.s3.eu-west-1.amazonaws.com',
};

const dev = {
    backendServiceUrl: 'https://dev.artworkgeneration.cimpress.io',
    playgroundApiUrl: 'https://dev.api.playground.artworkgeneration.cimpress.io',
    samplesS3BaseUrl: 'https://dev-artworkgeneration-playground-ui-settings.s3.eu-west-1.amazonaws.com',
};

const prod = {
    backendServiceUrl: 'https://artworkgeneration.cimpress.io',
    playgroundApiUrl: 'https://api.playground.artworkgeneration.cimpress.io',
    samplesS3BaseUrl: 'https://prod-artworkgeneration-playground-ui-settings.s3.eu-west-1.amazonaws.com',
};

const config = import.meta.env.MODE === 'production' ? prod : import.meta.env.MODE === 'development' ? dev : local;

const exportedConfig = {
    ...config,
};

export default exportedConfig;
