import { centralizedAuth, type Profile } from '@cimpress/simple-auth-wrapper';

export const auth = new centralizedAuth({ clientID: 'SHO4AUufHlrK7pl2BWc2uKc882KTAsdw' });

export type AuthProfile = Profile;

function redirectPath(): string {
    return window.location.pathname + window.location.search;
}

export async function initializeAuth(): Promise<void> {
    try {
        await auth.ensureAuthentication({
            nextUri: redirectPath(),
        });
    } catch (error) {
        return console.error(error);
    }
}
