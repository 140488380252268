import { Select } from '@cimpress/react-components';
import { useState } from 'react';
import predefinedComponents from '../../data/predefinedComponents.json';
import { toJsonString } from '../../tools';
import { Control } from '../layout';

interface SelectorOption {
    label: string;
    value: any;
}

interface ComposePresetSelectorProps {
    onSelect: (components: any) => void;
}

export const ComposePresetSelector = ({ onSelect }: ComposePresetSelectorProps) => {
    const [selection, setSelection] = useState<SelectorOption>();

    const onChangeSelect = (selected: SelectorOption | null | undefined) => {
        setSelection(selected ?? undefined);
        onSelect(JSON.parse(toJsonString(selected?.value)));
    };

    return (
        <Control>
            <Control.Title>Preset</Control.Title>
            <Select
                onChange={onChangeSelect}
                value={selection}
                options={predefinedComponents as SelectorOption[]}
                label="Preset"
            />
        </Control>
    );
};
