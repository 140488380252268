import { Card } from '@cimpress/react-components';
import { DocumentPreviewRenderer, DocumentUrlPreviewRenderer, ImageUrlPreviewRenderer } from './renderers';
import type { PreviewCardProps, PreviewType } from './types';

import classes from './PreviewCard.module.css';

const RENDERERS: Record<PreviewType, any> = {
    document: DocumentPreviewRenderer,
    documentUrl: DocumentUrlPreviewRenderer,
    imageUrl: ImageUrlPreviewRenderer,
};

export const PreviewCard = <T extends PreviewType>({ title, type, loader }: PreviewCardProps<T>) => {
    const PreviewRenderer = RENDERERS[type];

    return (
        <Card className={classes.card} isMinimal header={title}>
            <PreviewRenderer loader={loader} />
        </Card>
    );
};
